import React from "react";
import Log from "../scripts/log";

const FileExists = (file_url) => {
	var http = new XMLHttpRequest();
	http.open('HEAD', file_url, false);
	http.send();
	
	return http.status != 404;

};
export default FileExists;


