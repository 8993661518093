import React from "react";
import Image from "../../scripts/image";


const GrantList = props => {
	return(


		<div id="grant-list" className="cell">
          <p style={{color:"#fff", fontWeight:"900", marginTop:"7vmin"}}>Made possible by grants from</p>
          <div id="grant-images" className="cell">
            <div className="grid-x grid-margin-x small-up-3 medium-up-4">  
              <div className="grant-img-container cell">
                <Image src="/assets/nasa_logo.png" className="grant-img" alt="NASA" />

              </div>
              <div className="grant-img-container cell">
                <Image src="/assets/nih_logo_white.png" className="grant-img" alt="National Institute of Health" />
              </div>
              <div className="grant-img-container cell">
                <Image src="/assets/national_science_foundation_logo.png" className="grant-img" alt="National Science Foundation" />
              </div>
            </div>
          </div>
        </div>
    )
};

export default GrantList;