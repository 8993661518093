import React from "react";
import Track from "../scripts/track";
import styled from "styled-components";


// Props (required)
 // img = an image of the person we're quoting. Img should live in the main assets folder
 // quote = text of the quote
 // name = the quoted person's name



const QuoteContainer = props => {

	
  return(
    <>
      <Container className="grid-x grid-margin-x">
      	<InnerContainer className="small-12 medium-12 large-10 large-offset-1 cell">
					
					<div className="grid-x grid-margin-x" style={{alignItems: "center"}}>
						{props.img && 
		      	<Image className="small-2 medium-auto cell" src={"../../assets/" + props.img} />
		      	}
		      	<div className={props.img ? "small-10 medium-8 large-9 cell" : "small-12 cell"}>
		      		<blockquote>{props.quote}</blockquote>
		      		<p>{props.name}</p>
		      	</div>
				</div>
		</InnerContainer>
      </Container>
    </>
  )
}

export default QuoteContainer;

const Container = styled.div `
	margin-top: 1.25rem !important;
	text-align: center;
	margin: auto;
`
const InnerContainer = styled.div `
	padding: 10px;
	background-color: #fff;
	text-align: left;
	border-radius: 3px;


`

const Image = styled.img`
	border-radius: 180px;
	max-width: 120px;
`