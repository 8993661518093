import React from "react";

import {variantCopy} from "../templateCopyText.js";
import styled from 'styled-components';
import Image from "../scripts/image";

// This code allows us to pull from our bank of testimonials and show them to customers.


// Options:
  // masonry (bool) = display testimonials in a pinterest-like "masonry" view. Typically should be set to true.
  // numberOfTestimonials (int) = how many testimonials to show
  // singleLine (bool, optional) = put all testimonials on a single line for medium sized screens and up (recommend 3 max testimonials)
  // widthOverride (bool, optional) = override default large screen 10 columns (allow full width)
  // tags (string, optional) = only show testimonials that are tagged as (meditation, pain, productivity, stress)

// other
  // template (obj) = product template. This is tech debt from earlier when the same code could run 3 websites.


const FullTestimonials = props => {
  let numberOfTestimonialsToShow = 0; // init, set to 0 (show all)
  let widthOverride = false;
  let subContainerClasses = "cell small-12 medium-10 medium-offset-1 ";

  if (props.numberOfTestimonials) { // if the prop was passed, then show that many testimonials
    numberOfTestimonialsToShow = props.numberOfTestimonials;
  }

  if (props.widthOverride){ // if we've overriden the smaller size (IE the container is already small), then set to full-width (small-12)
    subContainerClasses = "cell small-12";
  }

  const testimonialInputs = {
    template: variantCopy["zenstant"], 
    numTestimonials: numberOfTestimonialsToShow /* 0 or blank = show all testimonials*/,
    tag: props.tag
  }

	return(
	
		<FullTestimonialsContainer id={props.id} className={"full-testimonials-container grid-x grid-margin-x " + (props.masonry ? "masonry" : "")}> {/* Add 'masonry' class for pinterest-like masonry*/}
			<div className={subContainerClasses}>				
				<FullTestimonialsSubcontainer id="" className={"full-testimonials-subcontainer grid-x grid-margin-x small-up-1 " + (props.singleLine ? "medium-up-" + numberOfTestimonialsToShow  : "medium-up-2")} additionalStyles={props.masonry ? getTestimonialContainerSize(props.masonry, variantCopy["zenstant"], 2, props.tag) : null}>
					{testimonials(testimonialInputs)}
				</FullTestimonialsSubcontainer>
			</div>
		</FullTestimonialsContainer>
	)
};


// styled-components

const FullTestimonialsContainer = styled.div`
  background-color: #F0F3FA; /* an extremely light blue (nearly white) */
  
  .quote-container{
    background-color: #E6EBF5; /* a very light blue (basically off-white) */
    padding: 2.5%;
    border-radius: 0.25rem;
    margin: 2%;
  }

  &.masonry{ // If the prop 'masonry' was passed, the below code will create a pinterest-like visual style (known as 'masonry')
    .full-testimonials-subcontainer{
      > .cell:nth-child(2n+1) { order: 1; }
      > .cell:nth-child(2n)   { order: 2; }

      &::before{
          content: "";
          flex-basis: 100%;
          width: 0;
          order: 2;
      }
      &::after{
          content: "";
          flex-basis: 100%;
          width: 0;
          order: 2;
      }
      flex-flow: column wrap;
    }
  }

  .quote-container .quote-text q{
    color: #FB637E; /* orange-y pink */
  }
}

`

const FullTestimonialsSubcontainer = styled.div`
	${ props=> props.additionalStyles };
  display:flex;
`


/* END testimonials section */



//  Scripts


const testimonials = (items) => {

    let quoteList = [];
    let numberOfTestimonialsToShow = items.numTestimonials;
    const quotes = items.template.testimonials;
    let theQuotes = [];

    

    if (items.tag){ // if a specific tag is supplied, rebuild the list with only tagged testimonials
      for (let j=0; j<quotes.length; j++){
        if (quotes[j].tags.indexOf(items.tag) > -1){
          theQuotes.push(quotes[j])
        }
      }
    } else {
     theQuotes = quotes;
    }


    if (numberOfTestimonialsToShow == 0 ){ // check to see if numTestimonials param was given, if not use total length of testimonials
      numberOfTestimonialsToShow = theQuotes.length;
    } else if(numberOfTestimonialsToShow >= theQuotes.length){
      numberOfTestimonialsToShow = theQuotes.length;
    }




    for (let i=0; i<numberOfTestimonialsToShow; i++){ // cycles through each testimonial
      
      
        let filePath = "";
        
        if (!theQuotes[i].imgFileName){ // check to see if imgFileName exists...
          filePath = '/assets/default-testimonial.jpg'; // ... if not, show the default image.
        }
        else {          // if the file name exists, then show the regular image
          filePath = '/assets/' + theQuotes[i].imgFileName;
        }
  
        quoteList.push(
          <div key={i} className="cell" style={{marginBottom: "3vmin"}} >
            <div className="grid-x grid-margin-x quote-container">
              <Image src={filePath} className="cell small-3" style={{borderRadius: "1000px"}} />
              <div className="quote-text cell small-8  medium-auto">
                <q>{theQuotes[i].quote}</q>
                <cite>{theQuotes[i].cite}</cite>
              </div>
            </div>
          </div>
        );
      }
    

    return quoteList;
  };


function countAllCharacters(str) {
  return str.split("");
}


const getTestimonialContainerSize = (masonry = false, template = variantCopy["zenstant"], columns=2, tagged = null) => {
  if (!masonry){return null} else{
    const viewportWidth = window.innerWidth;
    let quoteList = [];
    let totalQuoteCharacters = 0;

    
    if (tagged){ // if a specific tag is supplied, rebuild the list with only tagged testimonials
      for (let i=0; i<template.testimonials.length; i++){
        if (template.testimonials[i].tags.indexOf(tagged) > -1){
          totalQuoteCharacters += countAllCharacters(template.testimonials[i].quote).length;
          quoteList.push(template.testimonials[i]);
        }
      }
    } else {
      quoteList = template.testimonials;
      for (let i=0; i<template.testimonials.length; i++){
        totalQuoteCharacters += countAllCharacters(template.testimonials[i].quote).length;
      }
    }


    const aveCharsPerLine = 42;
    const avePxPerLine = 68;

    const aveTextPerTestimonial = Math.ceil(totalQuoteCharacters / quoteList.length);


    const sizeOfOneTestimonial = aveTextPerTestimonial * avePxPerLine / aveCharsPerLine; // Rough px size of a testimonial    

    let containerSize = sizeOfOneTestimonial * (quoteList.length / (quoteList.length > 1 ? columns : 1));
    if(containerSize < 170){containerSize = 170};


    let styleString = containerSize + "px";
    let largeMediaQuery = `
      @media only screen and (min-width: 40em) {
        max-height: `+ styleString + `
      }
    `
    let styleOutput = largeMediaQuery;
 
    return styleOutput;
    }
  }



export default FullTestimonials;
