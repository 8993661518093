import React from "react";
import {variantCopy} from "../../templateCopyText.js";
import {Link} from 'react-router-dom';
import Image from '../../scripts/image';



export const ShowPlayer = (self, template = variantCopy["zenstant"]) => {
    return(
      <>
        <div className="grid-x" style={{textAlign:"center", zIndex: "5"}} >
            <div className={self.state.isPlaying ? "pause-button " : "play-button "} 
              onClick={evt => {
                  evt.stopPropagation(); // avoid double-calling this event
                  evt.preventDefault();
                  self.setState({getDemo: true})
                  self.playPause(template);
                }}
            ></div>
            <div className="cell small-12" style={{display:"inline-flex", justifyContent:"center", alignItems:"center", marginTop:"5vh" }}>
              <Image src="/assets/headphones.svg" style={{height:"80%"}} />
              <p style={{marginBottom: "0", paddingLeft:"0.325rem", fontSize:"100%", textShadow: "0 0 8px #fff"}} >
                Headphones improve the effects
              </p>
            </div>

            <div className="cell small-12 medium-8 medium-offset-2 large-6 large-offset-3" style={self.state.getDemo ? {marginTop: "5vh"} : {marginTop: "5vh", display: 'none'}} >
              <p style={{marginBottom: "0", paddingLeft:"0.325rem", fontSize:"100%", textShadow: "0 0 8px #fff"}} >
                {/*Pain causing you insomnia, difficulty focusing, or lack of energy? There are solutions for that.*/}
                Like it?
              </p>
              <Link className="button" to="/products">Show me relief packages</Link>
            </div>
              
          </div>
        </>
       )
  }