import React, { PureComponent, Suspense, lazy } from "react";
import Track from "../scripts/track";
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown';
import {GetUrlParam} from '../scripts/getUrlParam';




import {Helmet} from 'react-helmet';
import HeaderAndNav from '../modules/zenstant/headerAndNav';
import Footer from '../modules/zenstant/footer';


import ConvertKitClaireForm from "../modules/convertKitClaireForm";


  	
// React Helmet (meta tag) information
const icoFilePath = '/assets/zenstant-favicon.ico';
const title = "Free Brain Audio for Stress, Pain, and Meditation | Zenstant";
const description = "Get 52 weeks of free brain audio to help with stress, pain, and medtation. Sound therapy, backed by dozens of NASA and NIH grants, built by neuroscientists.";


// CTA-form-specific information (copy can be found within the constructor)
const uid = "145ce6dea0";
const svForm = "5314350";


class FreeContent extends PureComponent {
  	constructor(props) {
		super(props);   
		this.state = {
		};

		Track(undefined, "ViewContent");
    
	

		const formContent = {
			cta: "Please send me free audio, every week, for a year!",
			giveawayTitle: "Free Year of Content",
			// discountCode: "deepzen-big-discount", // note this is used by ConvertKit as a fallback, changes must be made to the form (or new forms created)
			// discountedPrice: "9.95", // note this is used by ConvertKit as a fallback, changes must be made to the form (or new forms created)
			redirect: "https://zenstant.co/next-steps" // note this is used by ConvertKit as a fallback, changes must be made to the form (or new forms created)
		}


	    this.state = {
	      formContent: formContent
	    };

  	}

	componentDidMount(){
		const contentMarkdownPath = require("../assets/free52weeksOfContent.md");

		fetch(contentMarkdownPath)
		.then(response => {
			return response.text();
		})
		.then(text => {
			this.setState({bodyContent: text});
		})

		// Check to see if there is an affiliate ID and, if so, set it to state
		const affiliateId = GetUrlParam("referenceid"); // NOTE! Affiliate IDs must all use a "referenceid" url parameter for them to pass through
		if (affiliateId){this.setState({affiliateId: affiliateId})};

	};

	

	render(){
		return(
			<>
				<Helmet>
					<title>{title}</title>
		            <link id='ico' rel="icon"  href={icoFilePath}/>
		            <meta name="description"     
		            	content={description}
		            />
		            <meta property="og:url"                content="https://www.zenstant.co/free" />
		            <meta property="og:type"               content="website" />
		            <meta property="og:title"              content={title} />
		            <meta property="og:description"        content={description} />
		            <meta property="og:image"              content="https://www.zenstant.co/assets/zenstant-ogimg-default.png?v=2" />
		            <meta property="og:image:width"        content="940" />
		            <meta property="og:image:height"        content="788" />
		            <meta property="og:image:alt"    content="A woman getting relief with Zenstant" />
				</Helmet>
				<div className="grid-container fluid">
		      		<div className="grid-x grid-margin-x">     
		      			<div className="cell small-12">
							<HeaderAndNav/>	
							<ContentContainer className="grid-x grid-margin-x">
								<Content className="cell small-10 medium-8 large-6 ">
									<ReactMarkdown children={this.state.bodyContent} />
									<ConvertKitClaireForm 
					  	        		content = {this.state.formContent}
					  	        		uid = {uid}
					  	        		svForm = {svForm}
					  	        		affiliateId = {this.state.affiliateId}
					  	        	/>
								</Content>
							</ContentContainer>
							<Footer/>
						</div>
					</div>
				</div>
				
			</>
		)
	}
}

export default FreeContent;

const ContentContainer = styled.div`
	justify-content: center;
	align-items: center;
	min-height: 90vh;
`
const Content = styled.div`
	padding-top: 3rem;
	padding-bottom: 3rem;
	// padding: 3rem 4rem;
	// background-color: #F0F3FA;

	h1{
		font-size: 2rem;
	}

	h2{
		font-size: 1.6rem;
	}

	h1, h2 {
		left: -1.8rem;
		position: relative;
	}

	p{
		font-size: 1.1rem;
	}
`