import React from "react";

// Function to check if we're in dev mode and, if so, turn debug mode on for pixel tracking
const CheckForLocalHostGarrett = () => { // Garrett's version of checking for localhost

	let url = window.location.href; // get current url

	// Function to check if we're in localhost
	try { 
		if(url.split("http://")[1].split(":")[0] === "localhost"){// check to see if hostname is localhost
			return true;		
	}
	} catch (e) {
		return false;
	};


	return false;
}

const isLocalhost = Boolean( // React dev's version of checking for localhost
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const CheckForLocalHost = () => {return isLocalhost};

export default CheckForLocalHost;