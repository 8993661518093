import React, { PureComponent } from "react";
import {variantCopy} from "../../templateCopyText.js";
// import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import Track from "../../scripts/track";

import HeaderAndNav from "../../modules/zenstant/headerAndNav";

import DemoButton from '../../modules/zenstant/demoButton';

import Footer from '../../modules/zenstant/footer';

import SocialProof from '../../modules/zenstant/socialProof';
import ConvertKitForm from "../../modules/convertKitForm";

import {PlayPauseButton} from "../../scripts/audioPlayer";
import {LoadGoogleOptimize} from "../../scripts/loadGoogleOptimize";


const formBodyContentInHtmlBulletsUnused = `
  <p>This course includes:</p>
 <ol>

 	<li><strong>Overcoming that "I know I need to meditate but I just don't feel like it" hump</strong>. (Or "How I broke a 7 year gym-drought with this 'five-minute' hack.")</li>
	<li><strong>How to meditate consistently without willpower</strong> though a Stanford behavior design professors dead-simple 'habit trick'.</li>
	<li><strong>The half-second trick that'll make meditation habitual</strong> (without relying on wilpower).</li>
  <li>And my trick to getting through that 20-minute "my foot is asleep and I'm tired and nevermind I'm done meditating" moment</li>
  </ol>
`

const formBodyContentInHTML = `
<p>For offline and easier access, let us know where to send your free 'Instant Meditation' track so you can easily use it anywhere, on any device, at any time.</p>

`



class DemoLp3 extends PureComponent {
  constructor(props) {
    super(props);
    const formContent = {
			headline_include_effortless_meditation_unused: `
				No time now? Own this track (free), get a free short course on the easiest way to meditate regularly (even without your permission), PLUS an extra free bonus track for just an email.
			`, 
			headline:"Get offline access and take it anywhere, FREE ($9 value)",
			body: formBodyContentInHTML,
			cta: "Send me the track, please!",
			giveawayTitle: "Instant Meditation",
			discountCode: "deepzen-big-discount", // note this is used by ConvertKit as a fallback, changes must be made to the form (or new forms created)
			discountedPrice: "9.95", // note this is used by ConvertKit as a fallback, changes must be made to the form (or new forms created)
			redirect: "https://zenstant.co/lp/thank-you-and-special-meditation-offer" // note this is used by ConvertKit as a fallback, changes must be made to the form (or new forms created)
		}

    // const thisURL = checkURL(); // checks URL to set template. Can take a string param to force URLs (IE to check 'Endo' while on localhost. Only works on localhost)
    const thisURL = "zenstant"; // Uncomment above `thisURL` and delete this line to make webapp compatable with multiple websites (EndoZen, Zengraine, etc.)
    
    this.state = {
      fileName: "instant-zen-demo.mp3",
      formContent: formContent,
    };
    this.audioPlayer = React.createRef();
    Track(undefined, "PageView");
  }

  
    

  	componentDidMount(){
			const GoogleOptimize = LoadGoogleOptimize();
		};

	// Fuction fires to allow user to play, or pause, music.
	


  	render(){
  		const marginSize= "2.5rem";
  
        return(
          <>
          	<Helmet>
	          	<title>Start meditating in 3 minutes flat</title>
							<meta name="description"     content="Special offer for Mindfulness Daily users" />
							<meta property="og:url"                content={"https://www.zenstant.co/" + window.location.pathname} />
							<meta property="og:type"               content="website" />
							<meta property="og:title"              content="Start meditating in 3 minutes flat" />
							<meta property="og:description"        content="Special offer for Mindfulness Daily users" />
							{/*
							<meta property="og:image"              content={"https://www.zenstant.co/assets/productImages/" + this.state.productInfo.imgFileName} />
							<meta property="og:image:width"        content="940" />
							<meta property="og:image:height"        content="788" />
							*/}
	       		</Helmet>
			
						<div className="grid-container fluid">

							<HeaderAndNav hideAllMenuOptions/>

	          	<div className="grid-x grid-margin-x" style={{textAlign:"center", zIndex: "5", backgroundColor:"#F0F3FA", paddingTop: marginSize}} >
	          		{thePreHeadline &&
			        		<p className="h5 cell small-10 small-offset-1 h5" style={{fontWeight: 900, color:"#FB637E"}}>
			        			NASA funded neuroscientist created...
			        		</p>
			        	}
	          		<h1 className="cell small-10 small-offset-1" >
	          			New science that helps you <span style={{fontWeight: 900}}>get into a deeply meditative state</span> at the push of a button.
	          		</h1>
	          		<h2 className="cell small-10 small-offset-1 h4">
	          			{theSubhead}
	          		</h2>
	  	            <div className="cell small-12 medium-5" style={{alignSelf:"center", marginTop:marginSize, marginBottom:marginSize}}>
		  	            <h3 style={{marginBottom: marginSize, color: "rgb(77, 77, 77)"}}>
		  	            	Take a deep breath and tap Play for Instant Meditation
		  	            	</h3>
		  	            
		  	            <PlayPauseButton self={this} audio={this.state.fileName} includeHeadphones />

		  	        </div>
	  	        
	  	        
		  	        <div className="cell small-12 medium-8 large-6" style={{margin: "auto", marginTop:marginSize, marginBottom:marginSize}}>
		  	        	{/*{convertkitHTML()}*/} {/*old way to do form*/}
		  	        	<ConvertKitForm 
		  	        		content = {this.state.formContent}
		  	        		uid = "b8e8290f3e"
		  	        		svForm = "2664759"
		  	        	/>
		  	        </div>

		  	        <SocialProof />
              	
	                
	            </div>

	            <Footer />
	          </div>
          </>
        )
	}
}

export default DemoLp3



      




const trackingFunction = () => {
	Track(undefined, "Lead", "Download Instant Zen (meditation) lead magnet");
	return true;
}

const thePreHeadline = `
	NASA funded neuroscientist created...
`

const theSubhead = `
	An exclusive gift for Mindfulness Daily users.
`