import React from "react";
import Track from "./track";
import {variantCopy} from "../templateCopyText.js";
import headphones from "../assets/headphones.svg";
import loadingRipple from "../assets/loading-ripple.svg";





const playPause = (template = variantCopy["zenstant"], self) => {
	console.log("playPause function fired correctly");
	try{
		    if (self.audioPlayer.current.paused) {
		      console.log("Audio should be playing");
	
		      self.audioPlayer.current.play();

		      self.setState({        // set state
		        isPlaying: true,     // of playing to true 
		        playerStarted: true,  // and player started to true
						loading:false 		 // if this function fires then the audio has loaded correctly.

		      });
	
		      Track(undefined /*template*/, 'ViewContent' /*category*/, 'PlayDemo' /*name (FB)/action(GA)*/, undefined /*value*/, false/*is custom FB event*/);
	
		    } else {
		      self.audioPlayer.current.pause();
		      self.setState({ isPlaying: false });
		      Track(undefined /*template*/, 'ViewContent' /*category*/, 'PauseDemo' /*name (FB)/action(GA)*/, undefined /*value*/, false/*is custom FB event*/);
		    }
		}
		catch {
			console.log("loading failed");
			if (self.state.playAttempts < 4 || !self.state.playAttempts){ // If user pushes play too fast they throw an error... attempt to handle the error 3 times
				let totalAttempts = self.state.playAttempts +1;
				let waitTime = 100 * (2**(totalAttempts-1));
				self.setState({loading: true});

				console.log("Audio not loaded yet, retrying. Attempt #" + totalAttempts);
				self.setState({playAttempts: self.state.playAttempts + 1});
				setTimeout(() => {playPause(undefined, self); return;}, waitTime) // try again, waiting for a set time
			} else {self.setState({loading: false})}
		}
	};


const loadMedia = (fileName = '/zenstant-3min-no-voice-demo.mp3', self) => { // Grabs media when ready to avoid long download times
		console.log("Audio is loading");
    fetch('/assets/' + fileName)
      .then(data => {
        self.setState({
        	audioFile: data.url,
        	audioLoaded: true,
        });
    })
  
}





export const PlayPauseButton = (props) => {

	return(
		<>	
			<div className={(props.self.state.isPlaying ? "pause-button " : "play-button ") + (props.self.state.loading ? "audio-loading" : "")} 
  	              onClick={evt => {
  	                  evt.stopPropagation(); // avoid double-calling this event
  	                  evt.preventDefault();
											if (!props.self.state.audioLoaded){props.self.setState({loading: true, audioLoaded: false}); loadMedia(props.self.state.fileName, props.self)};
  	                  playPause(undefined, props.self);
  	                }}
  	            >
  	            {props.self.state.loading && <img src="/assets/loading-ripple.svg" style={{position:"absolute", width:"6rem", height:"6rem", maxWidth: "6rem", left: "-6rem",top: "-3rem", opacity: "0.3"}}></img>}
			</div>

			{props.includeHeadphones && 
			<div className="" style={{display:"inline-flex", justifyContent:"center", alignItems:"center", marginTop:"5vh" }}>
              <img src="/assets/headphones.svg" style={{height:"3rem%"}} />
              <p style={{marginBottom: "0", paddingLeft:"0.325rem", fontSize:"100%", textShadow: "0 0 8px #fff"}} >
                <em>Headphones are critical to experience the effects</em>
              </p>
            </div>
        	}

			{AudioTag(props.self)}
		</>
	)
}


const AudioTag = (self) => {
	return(
		<>
		{self.state.audioFile &&  // Only load audio player once component is mounted (see componentDidMount) aboce
		  	            
              <audio
                id="audioPlayer"
                crossOrigin="anonymous"
                ref={self.audioPlayer}
              >
                <source src={self.state.audioFile} type="audio/mpeg" /> 

              </audio>
        }
        </>
	)
}