import React, { Component } from "react";
// import ReactGA from "react-ga";
import {variantCopy} from "../../templateCopyText.js";
import {productCopy} from "../../productCopy.js";

import ReactPixel from 'react-facebook-pixel';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import Track from "../../scripts/track";

import ReactMarkdown from 'react-markdown';

import HeaderAndNav from '../../modules/zenstant/headerAndNav';
import Footer from '../../modules/zenstant/footer';




import SocialProof from '../../modules/zenstant/socialProof';
import FullTestimonials from '../../modules/fullTestimonials';

import SatisfactionGuarantee from '../../modules/zenstant/satisfactionGuarantee';


import GetDiscountedPrice from '../../scripts/getDiscountedPrice'; 
import SetGumroadLink from '../../scripts/setGumroadLink';

const product = "stillness"; // ick... hard coded :( TODO... don't hard code this

export default class Product extends Component {
	constructor(props) {
		super(props);

		this.state = {
			productID: "",
			productHref: "",
			theImgSrc: "",
			productID: product,
			productInfo: productCopy[product],
			fullPrice: productCopy[product].price,
			typicalConversionRate: 0.02, // adjusts the value of a product page view (pageViewValue = price * typicalConversionRate)
		  
		};
		// ReactGA.pageview(window.location.pathname + window.location.search); // record this page on GA
		ReactPixel.pageView();          // Track pageview on FB
	}

	componentDidMount(){
		document.body.scrollTop = 0; // For Safari
  		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		this.getSpecificProduct(); // Sets HTML to the proper product
		this.logContentView();
	}

	

	getSpecificProduct = () => {
		const theGumroadHref = SetGumroadLink(this.state.productInfo.gumroadLink);
		const theImgSrc = "/assets/productImages/" + this.state.productInfo.imgFileName;
		const discountedPrice = GetDiscountedPrice(); // checks for discounted price

		this.setState({
			productInfo: this.state.productInfo,
			productID: this.state.productID,
			productHref: theGumroadHref,
			theImgSrc: theImgSrc,
			discountedPrice: discountedPrice,
		})
	}


	logContentView = () => {
		let contentName = "Product Page: " + this.state.productInfo.title;
		let value;
		if (this.state.discountedPrice){value = this.state.discountedPrice} else { value = this.state.fullPrice};
		value = value * this.state.typicalConversionRate;

		Track(undefined /*product template*/, 'ViewContent' /*category*/, contentName /*name (FB)/action(GA)*/, value /*value*/, false /*is custom FB event*/)
	}

	gumroadButton = (params = false) => { // Create gumroad purchase button. Takes object:key params of 'fullwidth' and 'words'
	    let gumButtonStyle = {};
	    let text;

	    let gumroadLink = this.state.productInfo.gumroadLink;
	    try {
	      text = params.words;
	    } catch {text = null}
	   
	    if (params.fullwidth) {
	        gumButtonStyle = {
	          width: "100%",
	      }
	    } else {
	      gumButtonStyle = {}
	    }

	    return(

	      <a  className="gumroad-button button" href={this.state.productHref} target="_blank" data-gumroad-single-product="true" style={gumButtonStyle} >
	        {!text && <>Buy now</>} {text} 
	      </a>
	    );
	}


	styledPrice = () => {
		return(
				<>
					{this.state.discountedPrice 
						? <strike style={{color: "#000", fontWeight: "300"}}>$ {this.state.fullPrice}</strike> 
						: "$" + this.state.fullPrice}
					&nbsp;  
					{this.state.discountedPrice && "$" + this.state.discountedPrice}
				</>
			)
	}


	render(){
		let template = variantCopy.zenstant; // Feature creep... this allowed us to create several sites with the same formats, but isn't used. However it must be included or many things fall apart.
		
		return(
			<>
				<Helmet>
		            <title>{this.state.productInfo.title + " checkout page"}</title>
		            
		            <link id='ico' rel="icon"  href={'/assets/' + template.ico + '.ico'}/>
		            <meta name="description"     content={this.state.cleanDescription}/>
		            <meta property="og:url"                content={"https://www.zenstant.co/" + window.location.pathname} />
		            <meta property="og:type"               content="website" />
		            <meta property="og:title"              content={this.state.productInfo.title} />
		            <meta property="og:description"        content={this.state.cleanDescription} />
		            <meta property="og:image"              content={"https://www.zenstant.co/assets/productImages/" + this.state.productInfo.imgFileName} />
		            <meta property="og:image:width"        content="940" />
		            <meta property="og:image:height"        content="788" />
		        </Helmet>
				<div className="grid-container fluid">
					<HeaderAndNav productPage purchaseText={"Buy now"} href={this.state.productHref} />

					
						<>
					
							<ContentContainer className="grid-x grid-margin-x">
								
								<div className="small-12 medium-5 large-4 cell">
									<div className="show-for-small-only">
										<ProductTitle className="medium-h3">{this.state.productInfo.title}</ProductTitle>
										{this.state.productInfo.marketingTitle &&
											<MarketingTitle id="product-marketing-title" className="h4">
												 {this.state.productInfo.marketingTitle}
											</MarketingTitle>
										}
										<HR className="show-for-medium"/>

									</div>
									<img src={this.state.theImgSrc} />	

								</div>
								<div className="auto cell">
									<div className="show-for-medium">
										<ProductTitle className="medium-h3">{this.state.productInfo.title} checkout page</ProductTitle>
										{this.state.productInfo.marketingTitle &&
											<MarketingTitle id="product-marketing-title" className="h4">
												 {this.state.productInfo.marketingTitle}
											</MarketingTitle>
										}
										<HR className="show-for-medium"/>

									</div>

									<h2 className="h4">
										<span style={{textDecoration: "underline", textTransform: "uppercase"}}>
											Pre checkout message
										</span>
									</h2>
									<ReactMarkdown source={presalesContent}/>

									


									<div>
										Price: &nbsp;

										<Price>
											{this.styledPrice()}
										</Price>
									</div>
									
									<div onClick={() => Track(template, 'AddToCart' /*event type*/, this.state.productInfo.title /*event action (IE name))*/ , this.state.productInfo.price * 0.02, false)} >
						              {this.gumroadButton({words: "Buy " + this.state.productInfo.title + " now"})}
						            </div>
						            <em>All purchases protected by a 82 year guarantee. Either you're 100% happy or you're 100% refunded.</em>

						            
								</div>
		      
								
							</ContentContainer>

		          			{/*<SatisfactionGuarantee template={template} productInfo={this.state.productInfo} gumroadButton={this.gumroadButton({words: "Buy " + this.state.productInfo.title + " now"})} />*/}
		          			<SatisfactionGuarantee template={template} hideCTA/>

							
							<SocialProof />
						

		          		</>
	          		


					<Footer />

				</div>
			</>
		)
	}
}

const ContentContainer = styled.div`
	padding: 1.5rem;
	background-color: #F0F3FA;
`

const ProductTitle = styled.h1`
	margin-bottom: 0;
	padding-bottom: 0;
	font-weight: 900;
	color: #FB637E;
`

const MarketingTitle = styled.h2`
	font-weight: 900;
`

const HR = styled.hr`
	margin-top: 0;
	margin-bottom: 0.75rem;
`

const DemoContainer = styled.div``

const DemoInner = styled.div`
	display: inline-flex;
	align-items: center;
`


const AudioDemo = styled.audio``

const Price = styled.span`
	color: #B6351A;
	font-weight: 700;
	font-size: 110%;
`


const presalesContent = `

We're thrilled you decided to invest in ${productCopy[product].title}.

When you click the button below you'll be taken to our secure provider, "Gumroad", to complete your purchase.

##### **After your purchase, here's what to expect:**

First, you'll get a receipt from Gumroad... 

**Keep your receipt email**. It contains links to your content to download (you can also listen via the Gumroad player, but we don't recommend it.)

It's also the place you go if you decide that ${productCopy[product].title} and want a refund.

Second, we'll send you a few 'onboarding' emails to help you get the most out of your new purchase.

Third, to be *sure* that ${productCopy[product].title} is right for you, **we'll send an email in 5 days reminding you to ask for a refund if you're not 100% satisfied**. (I've never heard of another company reminding you to ask for a refund, but it feels like it's the right thing to do to be *100% positive* that you're happy.)

If you have any questions, please feel free to **[reach out](mailto:support@zenstant.co&subject=Question(s)})** and we'll answer as fast as we can (Lance and Garrett are dads...)

`




