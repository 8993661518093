import React, { PureComponent } from "react";
import {variantCopy} from "../../templateCopyText.js";
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import Track from "../../scripts/track";
import ReactPixel from 'react-facebook-pixel';
import ReactMarkdown from 'react-markdown';
import {GetUrlParam} from '../../scripts/getUrlParam';

import {LoadGoogleOptimize} from "../../scripts/loadGoogleOptimize";

import HeaderAndNav from "../../modules/zenstant/headerAndNav";
import QuoteContainer from "../../modules/quoteContainer";


import DynamicH1 from "../../modules/zenstant/dynamicH1";

import {PlayPauseButton} from "../../scripts/audioPlayer";

import ConvertKitForm from "../../modules/convertKitForm";
import SetGumroadLink from '../../scripts/setGumroadLink';


import SocialProof from '../../modules/zenstant/socialProof';
import Footer from '../../modules/zenstant/footer';





// CTA-form-specific information (copy can be found within the constructor)
const uid = "e0b8f4808c";
const svForm = "3905526";

// Landing page copy
const theTitle = "Start meditating in 3 minutes flat"

const thePreHeadline = `
	NASA-backed neuroscientist will help you
`

const theHeadline = `
	Reduce pain instantly, from anywhere, without drugs.
`

const theSubhead = `
	An audio based pain reducer so effective it can reduce pain to the level of opiates.
`

let supportingCopy;
/*
supportingCopy = `
Dear friend,

It all began with a bottle of sake and a question: "can we mess with the brain using music?"

Hi, I'm Garrett Dunham and the music you can listen to below is the lovechild of a world famous musician and an Ivy League professor of neuroscience with an IQ of 196.

This one question - "can we mess with the brain using music" - lead to strapping lasers to bat's heads, the only known allergy to bullfrog urine, and 40+ grants from NASA  and the National Institute of health (reality really is crazier than fiction).

The answer?

**Yes**

We can use music to influence the brain at a subconscious level.

Please enjoy free access to the track and if you like it, consider downloading it/joining our email list.

&dash; Garrett
`
*/

const formBodyContentInHTML = `
  <p>You'l also get a few emails explaining some of the science behind your new drug free pain relief tool.</p>
`



class DemoLp5 extends PureComponent {
  constructor(props) {
    super(props);
    // const thisURL = checkURL(); // checks URL to set template. Can take a string param to force URLs (IE to check 'Endo' while on localhost. Only works on localhost)
    const thisURL = "zenstant"; // Uncomment above `thisURL` and delete this line to make webapp compatable with multiple websites (EndoZen, Zengraine, etc.)
    
    const formContent = {
			headline: `
				Want the track free (forever)? For just an email you'll own it and can use it wherever, whenever.
			`, 
			body:formBodyContentInHTML,
			cta: "Send me this track, please!",
			giveawayTitle: "",
			discountCode: "deepzen-big-discount", // note this is used by ConvertKit as a fallback, changes must be made to the form (or new forms created)
			discountedPrice: "9.95", // note this is used by ConvertKit as a fallback, changes must be made to the form (or new forms created)
			redirect: "https://zenstant.co/lp/thank-you-and-special-meditation-offer" // note this is used by ConvertKit as a fallback, changes must be made to the form (or new forms created)
		}


    this.state = {
      fileName: "eagle-rock-plus-demo.mp3",
      formContent: formContent
    };
    this.audioPlayer = React.createRef();
    Track(undefined, "PageView");
  }

  
    

	componentDidMount(){
		const GoogleOptimize = LoadGoogleOptimize();

		if (typeof window !== "undefined") {
			const gumLink = SetGumroadLink(); // Load gumroad
		}

		// Check to see if there is an affiliate ID and, if so, set it to state
		const affiliateId = GetUrlParam("referenceid"); // NOTE! Affiliate IDs must all use a "referenceid" url parameter for them to pass through
		if (affiliateId){this.setState({affiliateId: affiliateId})};
	};




	render(){
		const marginSize= "2.5rem";

    return(
      <>
      	<Helmet>
        	<title>{theTitle}</title>
	        <link id='ico' rel="icon"  href={'/assets/zenstant-favicon.ico'}/>

					<meta name="description"     content="Special offer for Mindfulness Daily users" />
					<meta property="og:url"                content={"https://www.zenstant.co/" + window.location.pathname} />
					<meta property="og:type"               content="website" />
					<meta property="og:title"              content={theTitle} />
					<meta property="og:description"        content="Special offer for Mindfulness Daily users" />
					{/*
					<meta property="og:image"              content={"https://www.zenstant.co/assets/productImages/" + this.state.productInfo.imgFileName} />
					<meta property="og:image:width"        content="940" />
					<meta property="og:image:height"        content="788" />
					*/}
     		</Helmet>
	
				<div className="grid-container fluid">

					{/*<HeaderAndNav hideAllMenuOptions/>*/}

        	<div className="grid-x grid-margin-x" style={{textAlign:"center", zIndex: "5", backgroundColor:"#F0F3FA", paddingTop: marginSize}} >
        		{thePreHeadline &&
        		<p className="h5 cell small-10 small-offset-1 h5" style={{fontWeight: 900, color:"#FB637E"}}>
        			{thePreHeadline}
        		</p>
        		}
        		<DynamicH1 text={theHeadline} classes="cell small-10 small-offset-1" style={{fontWeight: 900}} />

        		{theSubhead &&
        		<h2 className="cell small-10 small-offset-1 h4" >
        			<em>{theSubhead}</em>
        		</h2>
        		}

        		

        		{supportingCopy && 
      			<div className="cell small-10 small-offset-1 large-8" style={{textAlign:"left", marginTop: marginSize}}>
      				<ReactMarkdown children={supportingCopy}/>
      			</div>
        		}
            <div className="cell small-12 medium-8 large-6" style={{alignSelf:"center", margin:"auto", marginTop:marginSize, marginBottom:marginSize}}>
	            <h3 style={{marginBottom: marginSize, color: "rgb(77, 77, 77)"}}>
	            	Try it, free forever. Just hit Play"
	            </h3>
	            
	            <PlayPauseButton self={this} audio={this.state.fileName} includeHeadphones />

	            <QuoteContainer 
	        			/*img="jerry-seinfeld.jpeg"*/ 
	        			quote="My husband has been having a lot of pain from post-cancer issues and it's been hard for him to fall asleep. I convinced him to try the track, and he fell asleep while listening!! So thankful he can sleep through the pain and that this was the way to make it happen."
	        			name="Elizabeth G."
	        		/>
  	        </div>
	        
	        

  	        <div className="cell small-12 medium-8 large-6" style={{margin: "auto", marginTop:marginSize, marginBottom:marginSize}}>


  	        	<ConvertKitForm 
  	        		content = {this.state.formContent}
  	        		uid = {uid}
  	        		svForm = {svForm}
  	        		affiliateId = {this.state.affiliateId}
  	        	/>
  	        </div>


  	        <SocialProof />
          	
              
          </div>

          <Footer />
        </div>
      </>
    )
	}
}

export default DemoLp5

