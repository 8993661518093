import React from "react";
import Image from "../../scripts/image";


const TheScience = props => {
  
	    let s = [];

	    const science = props.template.science;
	    let numEntries = science.length;
	    

	    for (let i=0; i<numEntries; i++){ // cycles through each testimonial
	      let filePath = '/assets/illustrations/' + science[i].imgFileName;
	      
	      s.push(
	        <div key={i} className="cell small-12 medium-6 large-4" >
	          <div className="grid-x grid-margin-x science-container"  >
	            <img src={filePath} className="cell"  />
	            <div className="cell small-12 medium-auto" >
	            	<h3>{science[i].head}</h3> 
	            	<h4>{science[i].subhead}</h4>
		            <p>{science[i].text}</p>
	            </div>
	          </div>
	        </div>
	      );
	    }

    return s;
};

export default TheScience;	

export const TheScienceStandardImplementation = props => {
	return(
		<div id="science-section-container" className="grid-x grid-margin-x">
            <div className="small-12 medium-10 large-8 medium-offset-1 large-offset-2 cell text-center">
              <h2 className="h5">Backed by proprietary science</h2>
            </div>


            <div className="small-12 medium-10 large-8 medium-offset-1 large-offset-2 cell">
              <p>Through 20 years of research at Brown University and backed by grants including NASA, The National Institute of Health, and the National Science Foundation, we discovered...</p>
              <div id="the-science-container" className="grid-x grid-margin-x small-up-1 medium-up-2">     
				<TheScience template={props.template}/>
                
              </div>
            </div>
              

			<div id="the-book" className="small-12 medium-8 large-4 medium-offset-2 large-offset-4 cell">
			  <p >
			    and even wrote the book on auditory neuroscience
			  </p>
			  <Image className="science-img" src="/assets/universal-sense-seth-horowitz-book.png" alt="The Universal Sense: How hearing affects the mind."/>
			  <p className="science-caption">The Universal Sense: How hearing affects the mind. By Dr. Seth Horowitz.</p>
			</div>
 
			<div id="the-whitepaper" className="small-12 medium-8 medium-offset-2 cell text-center" style={{paddingTop: "2.25rem", paddingBottom:"2.25rem"}}>
				<h3>Let's get technical: read our white paper</h3>
				<a className="button" href="/assets/zenstant-white-paper-Painblock.pdf" target="_blank">Painblock algorithm: Method, development, and validation</a>
			
			</div>
        </div>
	)
}