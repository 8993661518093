import React from "react";
import Track from '../../scripts/track';



export const TheBenefits = props => {
	    let b = [];

	    const benefits = benefitsCopy;
	    let numEntries = benefits.length;

	    for (let i=0; i<numEntries; i++){ // cycles through each testimonial
	      let filePath = '/assets/benefits images/' + benefits[i].imgFileName;
	      let isOdd
	      if (i%2 === 1){isOdd = true}else{isOdd = false}; // set isOdd to true if i is an odd number
	      														// to create an alternating left/right vs right/left pattern

	      b.push(
	        <div key={i} className="cell small-12" style={{marginBottom: "5rem"}}>
	          <div className="grid-x grid-margin-x benefits-container">
	            <img src={filePath} style={{height:"100%" /*fixes strange mega-height in safari/iOS*/}} className={"cell small-10 small-offset-1 medium-offset-0 medium-5 " + (isOdd ? "medium-order-2" : "")} />
	            <div className={"cell small-12 medium-7 " + (isOdd ? "medium-order-1" : "")} >
	            	<h3>{benefits[i].head}</h3>
	            	{benefits[i].subhead && <h4>{benefits[i].subhead}</h4>}
		            {benefits[i].text && <p>{benefits[i].text}</p>}
	            </div>
	          </div>
	        </div>
	      );
	    }

    return b;
};


export const TheBenefitsStandardImplementation = () => { // Used for most pages
	return(
		<div id="benefits-section-container" className="grid-x grid-margin-x">
		    <div className="section-header small-12 medium-10 large-8 medium-offset-1 large-offset-2 cell text-center">
				<h2 className="h5">Get targeted solutions for nearly every menstrual problem</h2>
		    </div>
	
	
		    <div className="small-12 medium-10 large-8 medium-offset-1 large-offset-2 cell">
				{/*<p>reasons</p>*/}
				<div id="the-benefits-container" className="grid-x grid-margin-x small-up-1">     
				      <TheBenefits />
				</div>
		    </div>
		</div>
	)
}


const benefitsCopy = [
	{
		head: "Your solution for when pain feels like 🔥🦖🔥",
		subhead: "Can give relief when even morphine doesn’t help",
		text: "Many women with unimaginably painful periods (endometriosis) have reported that our sound therapies have helped them when nothing else has.",
		imgFileName: "angry-uterus-raptors.jpg"
	},

	{
		head: "Get incredibly productive… even with a headache",
		subhead: "",
		text: "Suffer from headaches when you're on your period? Get incredibly focused on the things that matter at the push of a button with the Purely Productive series (part of Zenstant COMPLETE).",
		imgFileName: "its-showtime.jpg"
	},
	{
		head: "Get re-energized at the push of a button",
		subhead: "Your two solutions for exhaustion",
		text: "Pain giving you insomnia? Get out of your pain and out of your head with our insomnia ending tracks. Or take a 20 minute recharge with 'AussieNap', a didgeridoo based track that brings you to the brink of sleep and back. Both included in the Zenstant COMPLETE pack.",
		imgFileName: "location-location-location.jpg"
	},

	{
		head: "Pain relief that goes wherever you go",
		subhead: "The only menstrual relief that doesn’t take up space in your purse",
		text: "What's worse than having sudden cramps with no pain meds in sight? Now all you need is a pair of headphones and your phone to get some relief, anytime, anywhere.",
		imgFileName: "how-hot-heating-pad.jpg"
	},
	{
		head: "Stop popping dozens (or hundreds) of pills each month",
		subhead: "",  
		text: "Over-the-counter pain meds are great... but sometimes it's nice to give your liver a chance to relax. Get drug-free relief that gets more effective the more you use it.",
		imgFileName: "too-much-advil.jpg"
	},
	{
		head: "Turn menstrual cramps from  🔥🦖🔥 to 🏖 in 1/10th the time of OTC period drugs",
		subhead: "No pills, potions, nor pot required",
		text: "Average onset of relief from our therapies is about 3 minutes and can be used while you wait for conventional drugs to work.",
		imgFileName: "commercials-vs-reality.jpg"
	},
	
]



// Benefits copy template
/*
	{
		head: "",
		subhead: "",
		text: "",
		imgFileName: ""
	},

*/