import React from "react";

// Functions to watch for a user's first scroll and set the parent component's state of userScrolled to true.
	// Use with React.lazy (lazy loading) to hide below-fold components until user takes action.


// HOW TO USE
	// import {WatchForUsersFirstScroll} from ".../initialScrollWatcher"; // check path
	// initialize state of userScrolled to false
	// place `  WatchForUsersFirstScroll(this)  ` in componentDidMount();

export const WatchForUsersFirstScroll = (self) => {
	window.addEventListener('scroll', OnInitialScroll, false); // set the event listener to watch for scrolls
	window.parentsThis = self; // set the param
}

const OnInitialScroll = evt => {
	let parentsThis = evt.currentTarget.parentsThis;
	window.removeEventListener("scroll", OnInitialScroll); // remove event listener
    parentsThis.setState({userScrolled: true});
}
