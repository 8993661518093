import React, { Component, Suspense, lazy } from "react";
// import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'
import {Link} from 'react-router-dom';
import {LinkWithQuery} from '../modules/linkWithQuery';
import Log from "../scripts/log";
import styled from 'styled-components';


import HeaderAndNav from '../modules/zenstant/headerAndNav';
import Footer from '../modules/zenstant/footer';

import {productCopy} from "../productCopy.js";

import ReactMarkdown from 'react-markdown';
import Track from '../scripts/track';





export default class Products extends Component {
	constructor(props) {
		super(props);

		const totalProducts = productCopy.length;

		this.state = {
			productList: [],
			productsLoaded: false,
			totalProducts: totalProducts
		};
		// ReactGA.pageview(window.location.pathname + window.location.search); // record this page on GA
		ReactPixel.pageView();          // Track pageview on FB
	}

	componentDidMount(){
	}



	render(){
		return(
			<>
				<ContentContainer className="grid-container fluid">
					<HeaderAndNav/>
					<ContentInnerContainer className="grid-x grid-margin-x" >
						<H1 className="small-12 cell h2">Products</H1>
						{!this.state.productsLoaded && Object.keys(productCopy).map((productKey, index) => 
							{
								
								let product = productCopy[productKey];

								let descriptionID = "longDesc" + index;

								let imgSrc = "/assets/productImages/" + product.imgFileName;
								if (!product.abTest ){ // Don't show ab test files on the product page
									return (
									
										<ProductWrapper key={index} className="small-12 medium-6 large-4 cell">
											<ProductInner>

												<LinkWithQuery to={"/products/" + product.pageID}>
													<Suspense fallback={<div>Loading image...</div>}><img src={imgSrc} /></Suspense>
												</LinkWithQuery>
												<h3 className="h4">{product.title} </h3>
												{product.shortDescription && 
													<p><ReactMarkdown children={product.shortDescription} />
														{/*<a id={"showLongDesc" + index} onClick={
															()=> {
																document.getElementById(descriptionID).classList.remove("hidden");
																document.getElementById("showLongDesc" + index).classList.add("hidden");
		
																}
															} 
														>
															Expand
														</a>*/}
												</p>}
												<p className="" style={{color:"#B6351A"}}>${product.price}</p>
												<p id={descriptionID} className="hidden"><ReactMarkdown source={product.description}/></p>
												
												<div className="grid-x grid-margin-x" style={{alignItems: "center"}}>
													
													{/*{savedGumroadLink(product)}  */} {/*This function is a saved placeholder for where I had included an "add to cart" button on the products page. See the function for the original code, which is not used right now */}
													
													<LinkWithQuery to={"/products/" + product.pageID} className="button cell auto" style={{marginLeft: "1rem"}}>Get details on {product.title}</LinkWithQuery>
												</div>
												

											</ProductInner>
										</ProductWrapper>
									
									)
								}
								if(this.state.totalProducts === index){this.setState({productsLoaded: true})}

							}
						)}
					</ContentInnerContainer>
					<Footer/>
				</ContentContainer>


			</>
		)
	}

}

const H1 = styled.h1`
	color: #F0F3FA;
	color: #343539;
	font-size:1.5rem;
	font-weight: 700;
`
const ContentContainer = styled.div``
const ContentInnerContainer = styled.div`
	margin-top: 1.25rem;
	background-color: #ffffff;

`
const ProductWrapper = styled.div`
	margin: 1rem;
`
const ProductInner = styled.div`
	background-color: #F0F3FA;
	padding: 1rem;

`


const savedGumroadLink = (product) => {
	return(
	<div className="cell shrink" onClick={() => Track(undefined, 'AddToCart' /*category*/, product.title /*name (FB)/action(GA)*/, product.price * 0.01 /* value, cost of product times 1% */)} >
		<a  className="gumroad-button button" href={product.gumroadLink} target="_blank" >
	        Add to cart
	    </a>
	</div>
    )
}

