import React from "react";
// import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import styled from 'styled-components'


import {Helmet} from 'react-helmet';
import HeaderAndNav from '../modules/zenstant/headerAndNav';
import Footer from '../modules/zenstant/footer';



const Segmented = props => {
	ReactPixel.pageView(); // track pageview
	// ReactGA.pageview(window.location.pathname + window.location.search); // Tack pageview in GA
    const icoFilePath = '/assets/zenstant-favicon.ico';
	
	return(
		<>
			<Helmet>
				<title>Boom!</title>
	            <link id='ico' rel="icon"  href={icoFilePath}/>
	            <meta name="description"     
	            	content="We've put you in the bucket you requested." 
	            />
	            <meta property="og:url"                content="https://www.zenstant.co" />
	            <meta property="og:type"               content="website" />
	            <meta property="og:title"              content="Boom!" />
	            <meta property="og:description"        content="We've put you in the bucket you requested." />
	            <meta property="og:image"              content="https://www.zenstant.co/assets/zenstant-ogimg-default.png?v=2" />
	            <meta property="og:image:width"        content="940" />
	            <meta property="og:image:height"        content="788" />
	            <meta property="og:image:alt"    content="A woman getting relief with Zenstant" />
			</Helmet>
			<div className="grid-container fluid">
	      		<div className="grid-x grid-margin-x">     
	      			<div className="cell small-12">
						<HeaderAndNav/>	
						<ContentContainer className="grid-x grid-margin-x">
							<Content className="cell small-10 medium-8 large-4 ">
								<h1 className="h2">Boom!</h1>

								<p>You are now "segmented". It's an honor to serve you and our privilege for your attention.</p>
								<p>Expect more details soon.</p>
								<p>Garrett</p>

							</Content>
						</ContentContainer>
						<Footer/>
					</div>
				</div>
			</div>
			
		</>
	)
}

export default Segmented;

const ContentContainer = styled.div`
	justify-content: center;
	align-items: center;
	min-height: 90vh;
`
const Content = styled.div`
	padding: 3rem 4rem;
	background-color: #F0F3FA;
	
`