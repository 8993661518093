import React from "react";
import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import {variantCopy} from "../../templateCopyText.js";
import checkURL from '../../scripts/checkURL';
import Track from '../../scripts/track';
import {Link} from 'react-router-dom';


const currentURL = checkURL(); // Call the checkURL function which checks the current URL to be used with templateCopyText to get template specific content.
const template = variantCopy[currentURL]; // get template


const DemoButton = props => {
	let buttonClasses = "cell ";
	if (!props.buttonClasses){buttonClasses +=  "small-12 medium-5" // If the props 'buttonClasses' isn't defined, used default
	} else {buttonClasses += props.buttonClasses;}					// otherwise, use props.buttonClasses
	
	let buttonStyle;
	let text;
	let textStyle;
	let spanStyle;
	if (!props.link){
		buttonStyle = demoButtonStyle;
		buttonClasses += " button ";
	} else {
		buttonStyle = linkStyle;
		textStyle = linkTextStyle;
		spanStyle = {display:"none"};
	}

	return(
	
		<div className={buttonClasses} style={buttonStyle}
	        onClick={evt => {
	          evt.stopPropagation(); // avoid double-calling this event
	          evt.preventDefault();
	          Track(template /*product template*/, 'ViewContent' /*category*/, 'Show Demo' /*name (FB)/action(GA)*/, undefined /*value*/, false /*is custom FB event*/)
	          props.getDemo();
	          // props.playPause();
	        }}
	      >
	        <p style={textStyle}>
		        {props.words ? props.words : "Try a demo now"}<br/>
		        <span style={spanStyle}>100% free, no email or info</span>
	        </p>
	    </div>
	)
}
//
export default DemoButton;

const demoButtonStyle = {
	marginBottom: "2.5vh",
	alignItems: "center",
    display: "flex",
    justifyContent: "center",
    fontSize: "1.1rem",
    fontWeight: "700",
    color: "#4E5B73", 
    boxShadow: "0 0 1px #4E5B73",
    paddingTop: "0.65rem",
    paddingBottom: "0.65rem",
}

const linkStyle = {
	cursor:"pointer",
	alignItems: "center",
	display: "flex",
}

const linkTextStyle = {
	color: "#1779ba",
}
