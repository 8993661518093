import React, { Component } from "react";
import {variantCopy} from "../templateCopyText.js";
// import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

import Log from "../scripts/log";

// Function to send tracking data to Google Tag Manager and the FB pixel

// Template for use:
	// TRACK PAGEVIEW: Track()  NOTE: no props needed
	// TRACK ViewContent: Track(undefined, "ViewContent")
	// Tracking other things: see below for overview of props
	
	// note the 'template' (first prop) is nearly always undefined... it's feature creep from building this website to be useable across several domains.

const Track = (
		template = variantCopy["zenstant"], 
		eventType = "PageView" /*in GA, Event Category*/,
		eventAction = (window.location.pathname), 
		value = null, 
		custom = false
	) => {
	
		if (!custom){ // If custom prop is false, use FB's standard events
			ReactPixel.track( eventType, {
				content_category: template.product,
				content_name: eventAction,
				content_value: value,       
			});
		} else if(custom===true) { // If custom prop is true, use FB's custom events
			ReactPixel.trackCustom( eventType, {
				content_category: template.product,
				content_name: eventAction,
				content_value: value,       
			})
		}
		// ReactGA.event({ 
		// 	category: eventType,
		// 	action: eventAction,
		// 	label: template.product,
		// 	value: value
		// })

		let dataLayerName = 'PageDataLayer';
		let GTMArgs = {
			dataLayer: {
				event: "track",
				eventAction: eventAction,
				eventType: eventType, // for GA, this is the 'event category'
				value:value,
				label: template.product,
			  },
		  dataLayerName: dataLayerName
		};

		TagManager.dataLayer(GTMArgs);

		Log("Event Logged!")
		Log("GTM:'Event Category'/FB:'EventType' is: ", eventType)
		Log("GTM:'Label'/FB:'Category' is: ", template.product);
		Log("GTM:'Event Action'/FB:'Content Name' is:", eventAction);
		Log("Value is: ", value);



}

export default Track;