import React from "react";
import {Link} from 'react-router-dom';

const UltimateGuarantee = props => {
  return(
      <div className="grid-x grid-margin-x">
        <div className="cell medium-auto">
          <img src="/assets/82-year-guarantee.jpg" alt="82 Year Guarantee Seal"/>
        </div>

        <div className="cell small-12 medium-9">
          <h4 className="h5">Protected by the "we'll remind you to ask for a refund" Guarantee</h4>
          <p><em>Your purchased is protected by a 29,950-day (82 year/lifetime) money back guarantee and in 5 days we'll remind you to ask for a refund.</em></p>
         

        </div>
        
      </div>
  )
}


export default UltimateGuarantee;