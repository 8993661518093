import React, { PureComponent, Suspense, lazy } from "react";
import {variantCopy} from "../../templateCopyText.js";
import ReactPixel from 'react-facebook-pixel';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import Track from "../../scripts/track";

import ReactMarkdown from 'react-markdown';
import styled from 'styled-components'



import HeaderAndNav from "../../modules/zenstant/headerAndNav";
import Footer from '../../modules/zenstant/footer';

import SocialProof from '../../modules/zenstant/socialProof';
import DynamicH1 from "../../modules/zenstant/dynamicH1";
import FAQ from '../../modules/zenstant/faq';

import SetGumroadLink from '../../scripts/setGumroadLink';
import GetDiscountedPrice from '../../scripts/getDiscountedPrice';

import {WatchForUsersFirstScroll} from "../../scripts/initialScrollWatcher";

import {productCopy} from "../../productCopy.js";
import OrderFormBump from "../../scripts/orderFormBump";

import UltimateGuarantee from "../../modules/ultimateGuarantee";





const FullTestimonials = lazy(() => import ('../../modules/fullTestimonials'));
const TheBenefitsStandardImplementation = React.lazy(
  () => import('../../modules/zenstant/theBenefits').then(module => ({ default: module.TheBenefitsStandardImplementation })));
const TheScienceStandardImplementation = React.lazy(
  () => import('../../modules/zenstant/theScience').then(module => ({ default: module.TheScienceStandardImplementation })));
const SatisfactionGuarantee = lazy(() => import('../../modules/zenstant/satisfactionGuarantee'));



const productChoice = "stillness"
const fullPrice = productCopy[productChoice].price;
const discountedPrice = GetDiscountedPrice();
const bonusesValue = 20;
const offerRejectionLink = "/lp/offer-rejected?rejected-offer=" + productChoice;



class Demo1Thanks extends PureComponent {
  constructor(props) {
    super(props);   
    this.state = {
    	productChoice: productChoice,
    	whichTemplate: "zenstant",
    	regularPrice: fullPrice,
    	customerAlreadyHasThisProduct: "electrofocus" // TODO: REMOVE
    };

    Track(undefined, "ViewContent");
    
  }

  
    

  	componentDidMount(){
		
			const gumLink = SetGumroadLink(productCopy[this.state.productChoice].gumroadLinkWithBonus); // Load gumroad
  		const urlParams = new URLSearchParams(window.location.search);

			var valueOfHasUrlParam = urlParams.get("has");


			// Check if there is a discounted price...
			const newPrice = GetDiscountedPrice();
			this.setState({
			  gumroadLink: gumLink,
			  discountedPrice: newPrice,
			  bgVidSrc: "/assets/beach-vid.mp4",
			  componentMounted: true,
			  customerAlreadyHasThisProduct: valueOfHasUrlParam     // TODO UPDATE: Change based on what product they have already
// TODO ABOVE!
		});
	};

	

  	render(){
  		const marginSize= "2.5rem";
    	const template = variantCopy[this.state.whichTemplate];
    	const productChoice = this.state.productChoice;
    	const fullPrice = this.state.fullPrice;
		// const discountedPriceInUrl = GetDiscountedPrice();

  
        return(
          <>
          	<Helmet>
	           {/* <title>{'Product ' + this.state.productInfo.title + ' by Zenstant'}</title>
	           	            
	           	            <link id='ico' rel="icon"  href={'/assets/' + template.ico + '.ico'}/>
	           	            <meta name="description"     content={this.state.cleanDescription}/>
	           	            <meta property="og:url"                content={"https://www.zenstant.co/" + window.location.pathname} />
	           	            <meta property="og:type"               content="website" />
	           	            <meta property="og:title"              content={this.state.productInfo.title} />
	           	            <meta property="og:description"        content={this.state.cleanDescription} />
	           	            <meta property="og:image"              content={"https://www.zenstant.co/assets/productImages/" + this.state.productInfo.imgFileName} />
	           	            <meta property="og:image:width"        content="940" />
	           	            <meta property="og:image:height"        content="788" />*/}
	        </Helmet>
			{/*<div className="grid-container fluid">
							<HeaderAndNav purchaseText={"Buy now"} href={this.state.productHref} />*/}
			
			<div className="grid-container fluid">

				<HeaderAndNav hideAllMenuOptions />
	          	

	          	<div className="grid-x grid-margin-x" style={{zIndex: "5", backgroundColor:"#F0F3FA", minHeight:"80vh"}} >
	          		<div className="cell small-10 small-offset-1" style={{marginTop: marginSize, height: "auto"}}>
		          		<p className="text-center h5"><em>Your download is being prepared and should be ready shortly. While you wait, I'm curious...</em></p>
		          		<h1 style={{textAlign:"center", }}>
		          			Do you ever find it difficult to get into a meditative state?
		          		</h1>

		          		<div className="grid-x grid-margin-x" style={{marginTop: marginSize, marginBottom: marginSize}}>
			          		<div className="cell small-12 medium-10 large-7" >

			          			{/*<ReactMarkdown children={mainText} />*/}
			          			<ReactMarkdown children={mainTextV2} />


			          			{/* BENEFITS. NOTE BENEFITS WERE INCLUDED WITH MAINTEXT FOR V1 */}
			          			<ul>
						          	{productCopy[this.state.productChoice].benefitList.map((benefit, index) => {
													return(
														<li key={index} style={{color:"#FB637E", listStyleType:"\"✓ \"", fontWeight: 900}}>
															<span style={{color: "#000", fontWeight: 500}}><ReactMarkdown children={benefit} /></span>
														</li>
													)
												})}
											</ul>

											{/* V1 has benefits in-line with mainText, so comment out above benefits section */}
			          			<ReactMarkdown children={mainText2V2} />
			          			<ReactMarkdown children={bonusStackV2} />



			          			{/*<ReactMarkdown children={closerText} />*/}
			          			<ReactMarkdown children={closerTextV2} />

			          			
			          			{/*<ReactMarkdown children={productSpecificFinalText} />*/}
			          			<ReactMarkdown children={productSpecificFinalTextV2} />

			          			<h5>Your purchase includes:</h5>
											<ul>
						          	{productCopy[this.state.productChoice].comesWith.map((comesWith, index) => {
													return(
														<li key={index} style={{color:"#FB637E", listStyleType:"\"• \"", fontWeight: 900}}>
															<span style={{color: "#000", fontWeight: 500}}><ReactMarkdown children={comesWith} /></span>
														</li>
													)
												})}
											</ul>

											<div className="grid-x">
				          			<CTAcontainer className="small-12 medium-10 cell">
				          				<div className="grid-x">
					          				<h3 className="h4">Get the meditation "music" so good, you'll meditate without your own permission:</h3>
					          				<ul className="small-12 medium-10 large-8 cell">
									          	{productCopy[this.state.productChoice].benefitList.map((benefit, index) => {
																return(
																	<li key={index} style={{color:"#FB637E", listStyleType:"\"✓ \"", fontWeight: 900}}>
																		<span style={{color: "#000", fontWeight: 500}}><ReactMarkdown children={benefit} /></span>
																	</li>
																)
															})}
														</ul>

														<h5>Plus these bonuses offered here only:</h5>
					          				<div className="small-12 medium-10 large-8 cell"><ReactMarkdown children={bonusStackV2} /></div>


														<div style={{marginBottom: "1.25rem"}} className="small-12 cell">
															<div onClick={() => {Track(undefined, 'AddToCart' /*event type*/, productCopy[productChoice].title /*event action (IE name))*/ , (this.state.discountedPrice ? this.state.discountedPrice : this.state.regularPrice) * 0.02, false); this.setState({purchased: true}) }} >
												            <a  className="gumroad-button button" href={this.state.gumroadLink} /*href={SetGumroadLink(productCopy[productChoice].gumroadLink)}*/ target="_blank" >
														        Buy now {this.state.discountedPrice ? <><strike style={{color:"#999", fontSize:"80%"}}> ${this.state.regularPrice}</strike> ${this.state.discountedPrice}</> : <>${this.state.regularPrice}</>}
														    </a>
												      </div>
														</div>			
													</div>
												</CTAcontainer>
											</div>

											<UltimateGuarantee />



											{/* SEE V1 CTA BELOW AND INSERT HERE TO PUT BACK */}	

											{/*<OrderFormBump 
												label="Upgrade me to MAX" 
												self={this} 
												upgradeUrl={`https://gumroad.com/l/zenstantcomplete?wanted=true&variant=Include%20"MAX%20Strength"%20%2811%20additional%20tracks%29`}
												newFullPrice={97}
												newDiscountedPrice={3.7}
												discountCode="oto-50"
											>
												<p>Our strongest algorithms for just $1</p>
											</OrderFormBump>*/}





								    <br/>
								    <br/>
								    <br/>
								    {!this.state.purchased &&
								    <>
									    <Link to={offerRejectionLink} >No, I don't want this offer.</Link> 
									    <em>I acknowledge that it won’t be offered in the future and am ok paying full price.</em>
										</>
										} {this.state.purchased &&
											<>
										    <Link to="/lp/purchase-complete" ><button className="button">Click here once your purchase is complete!</button></Link>
										    <br/><br/>
										    <em><Link to={offerRejectionLink}>Actually, I changed my mind.</Link> I acknowledge that this offer won’t be offered in the future and am ok paying full price.</em>
									    </>
										}

							    
			          	</div>
			          	<div className="cell large-4 show-for-large">
			          		<img src={"/assets/productImages/" + productCopy[productChoice].imgFileName} /></div>
			          	</div>
		          	</div>	  

	            </div>
	            <SocialProof template={template}/>

	            <Footer />
	          </div>
          </>
        )
	}
}

export default Demo1Thanks

const CTAcontainer = styled.div`
	padding:15px;
	margin-bottom:15px;
	background:#ffffde;
	border:1px solid #ddd;
	align="center"

	& >div{
		
	}
`




/////////////////////////
// V2 Salesletter start
/////////////////////////


const mainTextV2 =

`**Or to stay in state long enough?**

If you've ever struggled to get into a meditative state quickly enough or got pulled out before you wanted to, let me tell you a story...

> **"Did you just force me to meditate?" He said.**

I had to smile \(feeling only slight guilty\)...

My friend had asked for a demo of our meditation "music"

> "I'll try it just for a minute" he had said \(that was 12 minutes earlier\)

Now, he looked completely Zen. 

You could see the tension melted out of his shoulders... his back... It was the first time I truly saw my friend at peace.

Is that how your meditation sessions go?

Or is it more like: 

>"I'm going to meditate now... just need to stop thinking and focus on my breath."

>"Shoot I forgot to call the sitter for Saturday."

>"Wait... my breath."

>................

>"I think it's working!"

>"UGH! PLEASE. STOP. THINKING!"

### **Sometimes it takes up to 10 minutes just to clear out the cruft from my brain** just so I can _start_ meditating.

I don't have that kind of _time_, do you?

**Or maybe you have no issues getting _into_ a meditative state.**

It's that moment, twenty minutes in, when your leg starts going numb, your back starts to hurt, and you think "maybe this is enough".

_Even though you wanted to meditate so much longer._

Wouldn't it be nice to have a tool to get through that hump?

You downloaded our meditation track to improve your meditation practice, and that's exactly what it'll do. 

But now we're offering everything you could need to get into a meditative state faster, stay in state longer, and finally 'turn your brain off'.

All at the push of "play".

### So now you can "cheat" and 'turn your brain off' (even without your permission) with this audio based 'brain music'

20 years ago, our neuroscientist, Dr. Seth Horowitz started studying how audio affects the brain.

He put people in MRIs and used audio to manipulate where blood was flowing in their brain.

He studied bullfrogs (bullfrogs are the closest animal to humans when it comes to hearing) so much he became the only known human to become allergic to bullfrog urine.

He even strapped lasers to bats to see how they used ultrasonic sound to "see".

The man literally wrote the book on auditory neuroscience, and **now you can get over 310 minutes of Dr. Horowitz's most potent content built specifically to help your meditative practice** and to help you:
`

const mainText2V2 =

`Plus, \$${bonusesValue} in extra bonuses if you buy _right now_ \(not offered elsewhere\):`

const bonusStackV2 =
`
1. **Finish next weeks todo list \(and today's\)**  with our 60 minute "audio Adderall" for your most creative and productive day ever. *$12 value.*
1. Feel like you've gained **3 hours of sleep in just 20 minutes** with "Aussie Nap", our power-nap inducing track. *$8 value*
`


const closerTextV2 = 

`**So you'll get all \$${fullPrice + bonusesValue} worth of meditation tracks and bonuses for just \$${discountedPrice}**.


Just scroll down, click the button and secure your offer using our service provider 'Gumroad', which will handle your information with their bank-level security.

Why the discount?

To be honest, we're tired of the lab (it's pushing 20 years!) and want to get this into the hands of people who need it.  

People like you. 

So consider this our launch gift to you if you act fast.

### All purchases are backed by our **29,950-day full money-back guarantee** (that's exactly 82 years).

##### I'll even send you a reminder to ask for a refund so you don't have to remember.

But don't delay, you can't get this offer anywhere else. **If you don't act, your price is \$${fullPrice} and you'll have to pay \$${bonusesValue} for the bonuses.**
`


const productSpecificFinalTextV2 =
`Or you can keep the frustration of those times that you _wanted_ to meditate, but something \(time, neck pain\) just got in the way.

Click below now and start getting help now.
`

/////////////////////////
// END V2 Salesletter 
/////////////////////////









/////////////////////////
// V1 Salesletter below
/////////////////////////

const productBenefits2 = [
	"11 audio tracks to rapidly **make cramps go from stabbing pain to barely noticeable** (in minutes)",
	"The ability to calm your mind before bed *that can help even an insomniac* rest deeply",
	"A simple way to help you **be super productive** *(even with a headache)*",
	"A 20 minute 'recharge' track that helps induce catnaps for abundant energy (WAY better than caffeine)",
	"Eight, 30-minute pain reducing tracks in an ambient style to give you variety",
	"Ways to improve motivation in the morning when you just don't feel like getting out of bed",
	"**Pain relief in your pocket** that goes wherever your phone goes (no extra stuff to steal room in your purse)",
	"280+ minutes of neuroscience backed audio content delivering a truly _drug-free solution_ to menstrual pain"
]






const mainText =

`**Or to stay in state long enough?**

If you've ever struggled to get into a meditative state quickly enough or got pulled out before you wanted to, let me tell you a story...

> **"Did you just force me to meditate?" He said.**

I had to smile \(feeling only slight guilty\)...

My friend had asked for a demo of our meditation "music"

> "I'll try it just for a minute" he had said \(that was 12 minutes earlier\)

Now, he looked completely Zen. 

You could see the tension melted out of his shoulders...

his back...

It was the first time I truly saw him at peace.

Is that how your meditation sessions go?

Or is it more like: 

>"I'm going to meditate now... just need to stop thinking and focus on my breath."

>"Shoot I forgot to call the sitter for Saturday."

>"Wait... my breath."

>"Did I reply to his email?"

>"Nonono stop, focus on my breath\!"

If you're like me **sometimes it takes up to 10 minutes just to clear out the cruft from my brain** just so I can _start_.

I don't have that kind of _time_, do you?

**Or maybe you have no issues getting _into_ a meditative state.**

It's that moment, twenty minutes in, when your leg starts going numb, your back starts to hurt, and you think "maybe this is enough".

_Even though you wanted to meditate so much longer._

Wouldn't it be nice to have a tool to get through that hump?

You downloaded our meditation track to improve your meditation practice, and that's exactly what it'll do. 

But now we're offering everything you could need to get into a meditative state faster, stay in state longer, **focus like Elon Musk**, and **enjoy life like Richard Branson**.

All at the push of "play".

Wouldn't _that_ be nice?

> ⭐️⭐️⭐️⭐️⭐️"I had this on repeat for an hour during a cross country flight and loved it. And I'm really picky\!" \- Walter Roth, founder of Inward Inc and Mindfulness Daily.
 

> ⭐️⭐️⭐️⭐️⭐️"Did you just force me to meditate? This is really good." Ari S.
 

> ⭐️⭐️⭐️⭐️⭐️"My husband has been having a lot of pain from post\-cancer issues and it's been hard for him to fall asleep. I convinced him to try the track, and he fell asleep while listening\!\! So thankful he can sleep through the pain and that this was the way to make it happen." \- Elizabeth G.
 

> ⭐️⭐️⭐️⭐️⭐️"I started to notice feelings of weightlessness that were really cool. I can honestly see this preventing my panic attacks if I listen to it when I feel one coming on. Very cool stuff\!" \- Valerie C.
 

If you buy _right now_ for only \$${discountedPrice} \(reg. \$${fullPrice}\) you'll get over 310 minutes of our most potent content built specifically to help your meditative practice and to help you:

* Get into a meditative state faster
* Stay in state longer
* **Get deeply relaxed in 5 minutes or less** with a classic rock styled QuickZen - for when you need calm in a hurry.
* Forget a stressful day and maximize your time with your partner, family, or friends.
* Reduce irritability caused by stress or anxiety.
* **Have your own personal island of relaxation**, ready when you need it.
* Lift your spirits when the weather is frightful.
* **Clear your mind and boost creativity.**

**What you'll get when you invest in yourself:**

* Over 10 albums worth of audio... for less than the price of one
* A 24\-minute piece so relaxing, we almost fell asleep
* Four hours of deeply relaxing audio in an 'ambient' style delivered as eight, 30\-minute variations to keep things fresh.
* A five\-minute 'QuickZen' in a classic rock style

Plus, \$${bonusesValue} in extra bonuses if you buy _right now_ \(not offered elsewhere\):

* **Finish next weeks todo list \(and today's\)**  with 60 minutes of the most intense productivity you can have. (Seriously, it's like audio Adderall, I've listened to it for over 156 hours now). *$12 value.*
* Feel like you've gained **3 hours of sleep in just 20 minutes** with "Aussie Nap", our power-nap inducing track. *$8 value*
`


const closerText = 

`**So you'll get all \$${fullPrice + bonusesValue} worth of meditation tracks and bonuses for just \$${discountedPrice}**.


Just scroll down, click the button and secure your offer using our service provider 'Gumroad', which will handle your information with their bank-level security.

Why the discount?

To be honest, we're tired of the lab (it's pushing 20 years!) and want to get this into the hands of people who need it. 

People who are tired of pain running their life. 

People like you. 

So consider this our launch gift to you if you act fast.

And if you happen to be the 1 out of 5 people who doesn't feel the effects then get your full money back with **our 29,950-day full money-back guarantee** (that's exactly 82 years). 

But don't delay, you can't get this offer anywhere else. **If you don't act, your price is \$${fullPrice} and you'll have to pay \$${bonusesValue} for the bonuses.**
`


const productSpecificFinalText =
`Or you can keep the frustration of those times that you _wanted_ to meditate, but something \(time, neck pain\) just got in the way.

Click below now and start getting help now.
`

const v1cta = (thiss = {}) => 
<div style={{marginBottom: "1.25rem"}}>
	<div  onClick={() => {Track(undefined, 'AddToCart' /*event type*/, productCopy[productChoice].title /*event action (IE name))*/ , (thiss.state.discountedPrice ? thiss.state.discountedPrice : thiss.state.regularPrice) * 0.02, false); thiss.setState({purchased: true}) }} >
          <a  className="gumroad-button button" href={thiss.state.gumroadLink} /*href={SetGumroadLink(productCopy[productChoice].gumroadLink)}*/ target="_blank" >
	        Buy now {thiss.state.discountedPrice ? <><strike style={{color:"#999", fontSize:"80%"}}> ${thiss.state.regularPrice}</strike> ${thiss.state.discountedPrice}</> : <>${thiss.state.regularPrice}</>}
	    </a>
      </div>
      <em className="small-10 cell">All purchases protected by a 29,950-day (82 year), no questions asked, full money back guarantee. Either you're 100% happy or you're 100% refunded. And yes, we'd probably even refund you 92 years from now if you asked.</em>
</div>



