import React from "react";
import {Link} from "react-router-dom";
import {LinkWithQuery} from '../linkWithQuery';
import {variantCopy} from "../../templateCopyText.js";
import Image from "../../scripts/image";
import styled from "styled-components"; 
import Track from "../../scripts/track";
import SetGumroadLink from '../../scripts/setGumroadLink';


// Available props:
	// hideAllMenuOptions (bool). Does what it says. Great for landing pages
	// productPage (bool). Checks for section options and adds appropriate links. Great for product pages
	// isNativeAd (bool). Hides all menu options EXCEPT for a purchase option. Great for 'native' style ads.





const menuItems = [ // NOTE: The last item in this list becomes a button
	// {
	// 	text: "Home", // what the menu item says
	// 	link: "/"  // where the menu item links
	// },
	// {
	// 	text: "Fun", // what the menu item says
	// 	link: "/funny/memes"  // where the menu item links
	// },
	{
		text: "Manifesto", // what the menu item says
		link: "/manifesto"  // where the menu item links
	},
	// {
	// 	text: "Listen", // what the menu item says
	// 	link: "/listen"  // where the menu item links
	// },
	{
		text: "Contact", // what the menu item says
		link: "/contact"  // where the menu item links
	},
	{
		text: "Products", // what the menu item says
		link: "/products"  // where the menu item links
	},
	// {
	// 	text: "Blog home", // what the menu item says
	// 	link: "/blog"  // where the menu item links
	// },
	// {
	// 	text: "Pain relief", // what the menu item says
	// 	link: "/products/zenstant-complete"  // where the menu item links
	// },
	{
		text: "Get 18 hours of free content", // what the menu item says
		link: "/free"  // where the menu item links
	},
	
];


const menuItemsOnProductPage = [
	{
		text: "Home", // what the menu item says
		link: "/",  // where the menu item links
		id: true, // a hacked way to ensure this shows on the menu
	},
	{
		text: "How it helps you", // what the menu item says
		link: "#benefits-section-container",  // where the menu item links
		id: "benefits-section-container", // the ID of the in-page element

	},
	{
		text: "The science", // what the menu item says
		link: "#science-section-container",  // where the menu item links
		id: "science-section-container", // the ID of the in-page element

	},
		{
		text: "What others say", // what the menu item says
		link: "#full-testimonials-container",  // where the menu item links
		id: "full-testimonials-container", // the ID of the in-page element

	},
	{
		text: "FAQ", // what the menu item says
		link: "#faq-container",  // where the menu item links
		id: "faq-container", // the ID of the in-page element

	},
	// {
	// 	text: "Blog home", // what the menu item says
	// 	link: "/blog"  // where the menu item links
	// },

];


const HeaderAndNav = props => {
	const largeLogoPath = "/assets/" + variantCopy.zenstant.logo;
	const notextLogoPath = "/assets/" + variantCopy.zenstant.logoNotext;
	
	let isNativeAd = false;
	if (props.isNativeAd){isNativeAd=true};

	let hideAllMenuOptions = false;
	if (props.hideAllMenuOptions){hideAllMenuOptions=true};

	

	return(
		<div id="header-and-nav-container" className="grid-x grid-margin-x" style={headerNavConatinerStyle}>
			<div id="logo-outer" className="small-2 medium-3 cell" style={{alignItems: "center", display: "flex"}}>

				<LinkWithQuery to="/">
            		<LogoLarge id="logoLarge"  src={largeLogoPath} alt="Zenstant" className="logo show-for-medium" />
            		<LogoSmall id="logoSmall"  src={notextLogoPath} alt="Zenstant" className="logo show-for-small-only" />
            	</LinkWithQuery>
            </div>
            {!hideAllMenuOptions && // only show nav options if hideAllMenuOptions is false
            <div id="nav-container" className="small-10 medium-9 cell">
            	<ul className="small-horizontal menu" style={navStyle}>
            		{!isNativeAd && !props.productPage && generateLinks()} {/*Don't show links on native ads*/}
            		{!isNativeAd && props.productPage && /* Show special links on product pages */
	            		<>	
	            			{fillProductPageMenu(menuItemsOnProductPage)}

							{false && 
	            			<li>
	            				<a className="button gumroad-button" href={SetGumroadLink()} target="_blank" data-gumroad-single-product="true" onClick={() => Track(undefined /*template*/, 'AddToCart' /*category*/, 'clicked-zenstant-painblock-gumroad-button' /*name (FB)/action(GA)*/)} >
								
									{props.purchaseText ? props.purchaseText : "I want this!"}
								</a>
							</li>
							}
						</>
            		} 

            		{isNativeAd && // Show a button for native ads
		        		<li><a className="button gumroad-button" href={SetGumroadLink()} target="_blank" data-gumroad-single-product="true" onClick={() => Track(undefined /*template*/, 'AddToCart' /*category*/, 'clicked-zenstant-painblock-gumroad-button' /*name (FB)/action(GA)*/)} >
							{props.purchaseText ? props.purchaseText : "I want this!"}
						</a></li>
		        	}
				</ul>
            </div>
        	}
        </div>
    )
}

const generateLinks = (items = menuItems) => {
	let output = [];

	for (let i = 0; i < items.length; i++){
		let theClass;
		if(i+1 === items.length){theClass = "button"}
		output.push(
	        
			<MenuBlock key={i}>
				<LinkWithQuery to={items[i].link} style={linkStyle} >
					<i className="fi-list"></i> 
					
					<span className={theClass}>{items[i].text}</span>	
				</LinkWithQuery>
			</MenuBlock>
        );
	}
	return output;
}


const fillProductPageMenu = (items = menuItemsOnProductPage) => {
	let menu = [];
	for (let i=0; i < items.length; i++){
		let menuOption = document.getElementById(items[i].id);
		let optionIsOnThisPage = (items[i].link.indexOf("#") >= 0); // check if this is an in-page link by checking for a hash (#)
		
		if (menuOption || items[i].id === true){
			menu.push(
				<MenuBlock key={i}>
					{optionIsOnThisPage &&
						<button onClick={() => menuOption.scrollIntoView({behavior: 'smooth'})} style={linkStyle}>
							<i className="fi-list"></i> 
							<span >{items[i].text}</span>	
						</button>
					}
					{!optionIsOnThisPage && 
						<LinkWithQuery to={items[i].link} style={linkStyle}>
							<i className="fi-list"></i> 
							<span >{items[i].text}</span>	
						</LinkWithQuery>
					}
				</MenuBlock>

				)
		}
	}
	return menu;

}


const MenuBlock = styled.li`
	& > a, & > button{
		color: #213343;

		& :hover, :focus{
		color: #172939;
		text-decoration: underline;
		}
	}

	& > button {
		line-height: 1;
	    text-decoration: none;
	    display: block;
	    padding: .7rem 1rem;

	}

	&:last-child a{
		padding: 0;  // the last-child automatically becomes a button, which has padding already applied, so this removes the padding
		padding-left: 1rem;
	}
`

const headerNavConatinerStyle = {
	paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
	// backgroundColor: "#FB637E",
	// backgroundColor: "#F0F3FA",
}


const LogoLarge = styled.img`
	max-height: calc(1.75rem);

`

const LogoSmall = styled.img`
	max-height: calc(1.75rem);
`



const navStyle = {
	height: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
}


const linkStyle = {
	// color:"#213343",
	fontWeight: "700",
}



export default HeaderAndNav;