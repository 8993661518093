import React from "react";
// import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import styled from 'styled-components'


import {Helmet} from 'react-helmet';
import HeaderAndNav from '../modules/zenstant/headerAndNav';
import Footer from '../modules/zenstant/footer';



const Contact = props => {
	ReactPixel.pageView(); // track pageview
	// ReactGA.pageview(window.location.pathname + window.location.search); // Tack pageview in GA
    const icoFilePath = '/assets/zenstant-favicon.ico';
	
	return(
		<>
			<Helmet>
				<title>New music helps women overcome menstrual pain without drugs</title>
	            <link id='ico' rel="icon"  href={icoFilePath}/>
	            <meta name="description"     
	            	content="New music helps women overcome menstrual pain without drugs though 20 years of neuroscience research." 
	            />
	            <meta property="og:url"                content="https://www.zenstant.co" />
	            <meta property="og:type"               content="website" />
	            <meta property="og:title"              content="New music helps women overcome menstrual pain without drugs" />
	            <meta property="og:description"        content="Get relief through your smartphone. Seriously. Free demo." />
	            <meta property="og:image"              content="https://www.zenstant.co/assets/zenstant-ogimg-default.png?v=2" />
	            <meta property="og:image:width"        content="940" />
	            <meta property="og:image:height"        content="788" />
	            <meta property="og:image:alt"    content="A woman getting relief with Zenstant" />
			</Helmet>
			<div className="grid-container fluid">
	      		<div className="grid-x grid-margin-x">     
	      			<div className="cell small-12">
						<HeaderAndNav/>	
						<ContactContentContainer className="grid-x grid-margin-x">
							<ContactContent className="cell small-10 medium-8 large-4 ">
								<h1 className="h2">Contact us</h1>
								<p>Thanks for your inquiry. For all questions or support, including refunds, please email <a href="mailto:support@limbic.me">support@limbic.me</a> (link opens email client).</p>

							</ContactContent>
						</ContactContentContainer>
						<Footer/>
					</div>
				</div>
			</div>
			
		</>
	)
}

export default Contact;

const ContactContentContainer = styled.div`
	justify-content: center;
	align-items: center;
	min-height: 90vh;
`
const ContactContent = styled.div`
	padding: 3rem 4rem;
	background-color: #F0F3FA;
	
`