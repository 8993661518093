import React from "react";
import ReactMarkdown from 'react-markdown';
import styled from "styled-components";


const fillFAQ = (text, shrink = false) => {
	let f = [];
	for (let i = 0; i < text.length; i++){
		let k = "faq-key-" + i;
		let contentId = "faq-content-id-00" + i;

		f.push(
			<FAQlistElement key={k} >

				<FAQquestion id={"h3-for-" + contentId} onClick={() => toggle({clickedElementId: "h3-for-" + contentId, elementIdToToggle: contentId})}  className={ shrink ? "h4" : "h4 open"} style={{fontWeight:"500", color:"#FB637E"}} >
					{text[i].question}
				</FAQquestion>

	    		<span id={contentId} style={ shrink ? {marginBottom: "3rem", display:"none"} : {marginBottom: "3rem", display:"inherit"} }>
					<ReactMarkdown source={text[i].answer} disallowedTypes={["code"]} unwrapDisallowed />
	    		</span>
			</FAQlistElement>
		)
	}
	return f;
}

const text = [
	{
		question: "Is this a placebo?",
		answer: "The short answer is no because placebos become less effective over time, yet our therapies actually **become more effective** over time.  \n\nI could tell you about our testing in randomized, double-blind studies using a control of our music without the neuroscience components. I could tell you that the therapy version's results absolutely shattered the music-only version's.  \n\nBut really, the important thing is that they work and *they continue to work* _(and get better)_ the more you use them. \n\nThis is also why we'll take all the risk and give you 100% of your money back any time within 6 months - with no questions asked and no hoops for you to jump through.  \n\n- If it *stops* working you get your money back.  \n- If you stop needing it you get your money back.  \n- If you decide you just don't like us any more, you get your money back."
	},
	{
		question: "How can it possibly block pain?",
		answer: `We can't.  \n\n Or more specifically, we can't block the actual pain signals to your brain.  \n\nInstead Zenstant works on the focus components (pulling your mind off the pain) and emotional components (feeling anxious/ruminating on the pain). The result is many people perceive less pain while listening and often after listening as well. \n\nAnd it works. Just look at the testimonials.`
	},
	{
		question: "What if I don’t like it?",
		answer: "You can get your money back anytime in the next 29,950 days (82 years). You may want to try a demo, first, to make sure you like it."
	},
	{
		question: "What if it stops working for me?",
		answer: "You can get your money back anytime in the next 29,950 days (82 years)."
	},
	{
		question: "What if I decide I don't like your company any more?",
		answer: "You can get your money back anytime in the next 29,950 days (82 years)."
	},
	{
		question: "Is there any scenario that I can't get my money back in the next 29,950 days (82 years)",
		answer: `**No**, there is no scenario where we won't give you your money back if you're unhappy. \n\nEven if you decide to be horrible to us (which we know you'd never do), we'll still give you your money back.`
	},{
		question: "Why do you have such a relaxed, and long, money back guarantee?",
		answer: "We know our product sounds too good to be true.  \n\nFantastical.  \n\nEven total bulls\*\*t.  \n\nIf it doesn't work, if you don't like it, if it stops working - that's our problem, not yours. There isn't, and shouldn't be, any risk to you.  \n\nBut maybe if you really, truly, like the product. And it helps make your life better. You'll tell us - or maybe even a friend?  \n\nThat's why there's such a relaxed policy... we really want you to be happy and realize that this just sounds too good to be true so we'll take all the risk to help ease your concerns and give you a chance to enjoy the relief."
	},
	{
		question: "How is this different from peaceful/relaxing music?",
		answer: "If you're like us, you know what music makes you calm and may have created a playlist to match. \n\nUnlike a playlist of peaceful music, though, Zenstant delivers tested and targeted neuroscience wrapped in music - kind of how Advil is medicine wrapped in a candy coating."
	},
];

{/*// */}

const FAQ = props => {
	let txt;

	if(props.text){txt = props.text}else{txt = text};
	let showShrunk = null;
	if (props.shrink){showShrunk = true}
	return(
		<div id="faq-container" className="grid-x grid-margin-x">
			<div className="section-header cell small-12 text-center" >
				<h2 className="h5">Frequently asked questions</h2>
			</div>
          	<div className="cell small-12 medium-8 medium-offset-1">
	            <div className="grid-y">
	                <ul className="no-bullet">
						{fillFAQ(txt, showShrunk)}
					</ul>
            	</div>
         	</div>
        </div>
	)
}

const toggle = (inputObj) => {
	var question = document.getElementById(inputObj.clickedElementId)
	var ans = document.getElementById(inputObj.elementIdToToggle);
	if (ans.style.display === "none") {
		ans.style.display = "inherit";
		question.classList.add("open");
	} else {
		ans.style.display = "none";
		question.classList.remove("open");

	}
}



export default FAQ;


const FAQlistElement = styled.li`
	margin-left: 1.25rem;

	& .open{

	}
`


const FAQquestion = styled.h3`
	display: inline-flex;
	cursor: pointer;
	&::before{
		content: "+";
	    align-items: baseline;
	    display: flex;
	    font-size: 85%;
	    padding-right: 0.25rem;
	    color: #333366;
	}

	&.open {
		&::before{content: "-"}
	}
`

