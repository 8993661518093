import React from "react";
import Image from "../../scripts/image";
import styled from 'styled-components';


const SocialProof = props => {

	return(
		<div id="social-proof" className="grid-x grid-padding-x" style={spContainerStyle}>
			<div className="small-12 cell ">
				{/*<
				<div className="grid-x grid-margin-x">	
					div className="small-6 medium-6 column cell proof-item">
											<p>With grants from:</p>
										</div>	
					<div className="small-6 cell proof-item">
						<p>Research featured in</p>
					</div>
				</div>
				*/}
				<FeatureText>Our research featured in</FeatureText>
				<div className="grid-x grid-margin-x small-up-4 medium-up-6 large-up-8" style={{justifyContent: "center", alignItems: "center"}} >
					
					<LogoWrap className="cell proof-item">
						<Image src="/assets/npr_logo_white.png" />
					</LogoWrap>
					<LogoWrap className="cell proof-item">
						<Image src="/assets/popular-science.png" />
					</LogoWrap>
					<LogoWrap className="cell proof-item show-for-medium">
						<Image src="/assets/gizmodo.png" />
					</LogoWrap>
					<LogoWrap className="cell proof-item">
						<Image src="/assets/wired.png" />
					</LogoWrap>
					<LogoWrap className="cell proof-item">
						<img style={{maxHeight: "3rem"}} src="/assets/nasa-white.png" />
					</LogoWrap>
				</div>
			</div>
		</div>
	)
};

export default SocialProof;

const LogoWrap = styled.div`
	text-align: center;
	justify-content: center;
	align-items: center;
`

const spContainerStyle = {
	background:"#FB637E",
	paddingTop: "1.25rem",
	paddingBottom: "2rem",
}

const FeatureText = styled.div`
	color: #c51e21;
	padding-bottom: 0.75rem;
	text-align: center;
`