import React, { PureComponent, Suspense, lazy } from "react";
import Track from "../scripts/track";
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown';



import {Helmet} from 'react-helmet';
import HeaderAndNav from '../modules/zenstant/headerAndNav';
import Footer from '../modules/zenstant/footer';


import ConvertKitClaireForm from "../modules/convertKitClaireForm";


  	
// React Helmet (meta tag) information
const icoFilePath = '/assets/zenstant-favicon.ico';
const title = "Manifesto | Zenstant";
const description = "We are the creators, the doers, the elite performers";


// CTA-form-specific information (copy can be found within the constructor)
const uid = "051d239b45";
const svForm = "2780148";


class Manifesto extends PureComponent {
  	constructor(props) {
		super(props);   
		this.state = {
		};

		Track(undefined, "ViewContent");
    
	

		const formContent = {
			cta: "Subscribe me, please!",
			giveawayTitle: "Instant Meditation",
			discountCode: "deepzen-big-discount", // note this is used by ConvertKit as a fallback, changes must be made to the form (or new forms created)
			discountedPrice: "9.95", // note this is used by ConvertKit as a fallback, changes must be made to the form (or new forms created)
			redirect: "https://zenstant.co/lp/thank-you-and-special-meditation-offer" // note this is used by ConvertKit as a fallback, changes must be made to the form (or new forms created)
		}


	    this.state = {
	      formContent: formContent
	    };

  	}

	componentDidMount(){
		const manifestoMarkdownPath = require("../assets/manifesto.md");

		fetch(manifestoMarkdownPath)
		.then(response => {
			return response.text();
		})
		.then(text => {
			this.setState({manifestoMd: text});
		})

	};

	

	render(){
		return(
			<>
				<Helmet>
					<title>{title}</title>
		            <link id='ico' rel="icon"  href={icoFilePath}/>
		            <meta name="description"     
		            	content={description}
		            />
		            <meta property="og:url"                content="https://www.zenstant.co" />
		            <meta property="og:type"               content="website" />
		            <meta property="og:title"              content={title} />
		            <meta property="og:description"        content={description} />
		            <meta property="og:image"              content="https://www.zenstant.co/assets/zenstant-ogimg-default.png?v=2" />
		            <meta property="og:image:width"        content="940" />
		            <meta property="og:image:height"        content="788" />
		            <meta property="og:image:alt"    content="A woman getting relief with Zenstant" />
				</Helmet>
				<div className="grid-container fluid">
		      		<div className="grid-x grid-margin-x">     
		      			<div className="cell small-12">
							<HeaderAndNav/>	
							<ContentContainer className="grid-x grid-margin-x">
								<Content className="cell small-10 medium-8 large-6 ">
									<ReactMarkdown children={this.state.manifestoMd} />
									<ConvertKitClaireForm 
					  	        		content = {this.state.formContent}
					  	        		uid = {uid}
					  	        		svForm = {svForm}
					  	        	/>
								</Content>
							</ContentContainer>
							<Footer/>
						</div>
					</div>
				</div>
				
			</>
		)
	}
}

export default Manifesto;

const ContentContainer = styled.div`
	justify-content: center;
	align-items: center;
	min-height: 90vh;
`
const Content = styled.div`
	padding-top: 3rem;
	padding-bottom: 3rem;
	// padding: 3rem 4rem;
	// background-color: #F0F3FA;

	h1{
		font-size: 2rem;
	}

	h2{
		font-size: 1.6rem;
	}

	h1, h2 {
		left: -1.8rem;
		position: relative;
	}

	p{
		font-size: 1.1rem;
	}
`