import React from "react";
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import Track from '../../scripts/track';

const ctaButton = <div ><Link className="button" to="/products/zenstant-complete">See relief solutions</Link></div>


const SatisfactionGuarantee = props => {
  
  let template;
  if (!props.template){template=undefined}


	return(


		<SatisfactionContainer id="satisfaction-guaranteed-container" className="grid-x grid-margin-x">
          <div className="medium-2 medium-offset-1 cell show-for-medium-up"> 
            <img style={{maxHeight: "100px"}} src="/assets/82-year-guarantee-dark-small.jpg"/>

          </div>

          <div className="small-12 medium-8 cell">
            <div className="grid-x grid-margin-x">
            <SatisfactionText id="satisfaction-text" className="cell small-12 /*medium-shrink*/ medium-text-left">
              <p>
                Happiness 100% guaranteed for 82 years
              </p>
            </SatisfactionText>
            
            {props.gumroadButton
              ? (
                <div 
                    className="cell small-12 medium-12 medium-text-left"
                    onClick={() => Track(template, 'AddToCart' /*event type*/, props.productInfo.title /*event action (IE name))*/ , props.productInfo.price * 0.02, false)}
                  >


                    {props.gumroadButton}
                </div>
              )
              : (
                props.hideCTA
                  ? (<></>) 
                  : (
                  <div className="cell small-12 medium-4 medium-text-left">
                    {ctaButton}
                  </div>
                  )
              )
            }
            </div>
          </div>

          
        </SatisfactionContainer>

    )
}

export default SatisfactionGuarantee


const SatisfactionContainer = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #213343; /* a dark blue*/
  padding-top: 5vh;
  padding-bottom: 5vh;
`

const SatisfactionText = styled.div`

  & p{
    font-size: 200%;
    line-height: 125%;
    color: #FB637E; /* orange-y pink */
    margin-bottom: 0;
  }
`