import React from "react";

// This checks for a given URL parameter and, if it exists, returns the contents.

export const GetUrlParam = (inputParam = null) => {
  if (!inputParam){return null}; // if nothing passed... stop everything and return nothing.

  if(!inputParam.includes("=")){
    inputParam = inputParam + "=";
  }

  let url = window.location.href; // get current url
  let paramOutput = null;

  try {
    let allParams = url.split("?")[1];        // first, drop everything before the URL parameter delimeter ("?")
    let s = allParams.split(inputParam)[1];   // then check for the param and split, taking later half of url
    let justTheParamContents = s.split("&")[0];        // then split off and ignore any trailing params. What's left is the param we want

    let paramOutput = justTheParamContents.replace(/\+/g, "%20");

    return decodeURIComponent(paramOutput);  // before returning, unencode text to remove %20 et. al.
  } catch {
    return null;
  }
  if (!paramOutput){return null}
  return paramOutput;
 }; 