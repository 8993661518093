export const productCopy = {
	"pain-pause": {
		"pageID": "pain-pause",
		"tag": "pain", // only show testimonials that include this tag, leave blank to show all testimonials

		"title": "Pain Pause",
		
		"marketingTitle": "Get targeted pain relief in minutes or your money back.",
	
		
		"marketingTitle": "When you want pain relief fast, from anywhere.",
		"shortDescription": "The original neuroscience audio for pain. The goes-wherever-your-phone is pain reliever called \"Dr. Horowitz's crowning achievement\".",
		
		"description": 
			"Can give you relief from chronic and temporary pain in minutes - wherever you are. It combines decades of our proprietary auditory neuroscience research with music to deliver relief that goes anywhere your phone goes - no pills required." +
			"\n\n" +
			" >\"I'm honestly shocked that this worked!\" \n\n "
			,
		"benefitList": [
			"Get the ability to rapidly **take pain from \"stabbing\" to barely noticeable in minutes**",
			"Eight, 30-minute pain reducing tracks in an ambient style to give you variety",
			"**Pain relief in your pocket** that goes wherever your phone goes (no extra stuff to carry)",
			"800+ minutes of neuroscience backed audio content delivering a truly _drug-free solution_ to  pain",
		],
		"comesWith": [
			"32 total audio tracks",
			"849 minutes of audio content (equivalent to 283 regular length pieces of music, or over 13 albums)",
			"Tracks categorized into 'albums' based on function, not marketing names",
			"EVERY new track we create for Pain Pause (roughly FOUR HOURS per month)"
		],
		"demos": [
			{
				file: "bells-plus-demo.mp3",
				title: "Bells PLUS"
			},
			{
				file: "bells-max-demo.mp3",
				title: "Bells MAX"
			},
			{
				file: "eagle-rock-plus-demo.mp3",
				title: "Eagle Rock PLUS"
			},
			{
				file: "light-spacewalk-plus-demo.mp3",
				title: "Light Spacewalk PLUS"
			},
			{
				file: "breath-of-the-earth-plus-demo.mp3",
				title: "Breath of the Earth PLUS"
			},
		],
		
		"trackList":[
			"Eagle Rock PLUS/MAX (classic rock style)",
			"Breath of the Earth PLUS/MAX",
			"Bells PLUS/MAX",
			"Mellodrop PLUS/MAX",
			"Dark Mellodrop PLUS/MAX",
			"Spacewalk PLUS/MAX",
			"Dark Spacewalk PLUS/MAX",
			"Moan of the Earth PLUS/MAX",
			"Darkened Moan of the Earth PLUS/MAX",
			"World Chorus PLUS/MAX",
			"Darkened World Chorus PLUS/MAX",
			"Mill Beach PLUS/MAX",
			"Fingal's Grotto PLUS/MAX",
			"Cosmic Cinema PLUS/MAX",
			"Soar PLUS/MAX",
			"Morning Dew PLUS/MAX",
			"Spelunking PLUS/MAX",
			"Drops of Eden PLUS/MAX",
			"Grand Adventure PLUS/MAX",
			"Desert Moon PLUS/MAX",
			"Luminous Depths PLUS/MAX",
			"Night Flight PLUS/MAX",
			"Dewdrops PLUS/MAX", 
			"Off-road Memories PLUS/MAX", 
			"Desert Dances PLUS/MAX",
			"Noise Gates PLUS/MAX",
			"Palm Winds PLUS/MAX",
			"Calm Winters PLUS/MAX",
			"Opening Time PLUS/MAX", 
			"Solace Spaces PLUS/MAX",
			"Future Nostalgia PLUS/MAX",
			"Light Nights PLUS/MAX"
		],
		"gumroadLink": "https://gumroad.com/l/pain-pause",
		"sendToGumroad": true, // Sends them to Gumroad for checkout due to the two variants
		"imgFileName": "pain-pause.jpg",

		"price": "45 (PLUS or MAX), $67.50 (for both)"
	},


	"stillness": {
		"pageID": "stillness",
		"tag": "meditation", // only show testimonials that include this tag, leave blank to show all testimonials

		"title": "The Stillness Collection",
		
		"marketingTitle": "The fastest way to get into a meditative state or stay in state.",
		
		"shortDescription": "Everything you could need for incredible clarity and a state of near-instant Zen. Can help improve meditation, boost productivity, and calm the mind.",

		"description": 
			"Stillness is **everything you could need for incredible mental clarity**" +
			"\n\n" + 
			"It combines decades of our proprietary auditory neuroscience research with music to deliver relief that goes anywhere your phone goes - no pills required.",
		"benefitList": [
			"Be able to **\"turn off your brain\"** fast (even without your permission) to get into a meditative state faster",
			"Get the popular classic rock styled \"Eagle Rock\" for a rapid, five\-minute deep meditation session",
			"Have an album full of \"guided meditations without words\" to **put you into deep meditative states quickly**",
			"Get the \"Hard Zen\" track built for pro meditators to get into state fast and stay in state even when your foot falls asleep",			
		],
		"comesWith": [
			"33 total tracks",
			"12 albums worth of audio (753 minutes, or ~251 regular length pieces)",
			"A 24\-minute piece so relaxing, we almost fell asleep",
			"Four hours of deeply relaxing audio in an 'ambient' style delivered as eight, 30\-minute variations to keep things fresh.",
			"The exclusive \"Hard Zen STILL\" and \"Instant Zen STILL\" tracks built solely for meditation",
			"EVERY new track we create for The Stillness Collection (roughly two 20 minute pieces per month)"
		],
		"demos": [ // TODO: ADD MORE
			{
				file: "instant-zen-demo.mp3",
				title: "Instant Zen - STILL"
			},
		],
		"trackList":[ 
			"Instant Zen STILL (for rapidly getting into a meditative state)",
			"Hard Zen STILL (for pro meditators to extend their meditation sessions)",
			"Eagle Rock STILL (classic rock style)",
			"Breath of the Earth STILL",
			"Mellodrop STILL",
			"Dark Mellodrop STILL",
			"Spacewalk STILL",
			"Dark spacewalk STILL",
			"Moan of the earth STILL",
			"Darkened moan of the earth STILL",
			"World chorus STILL",
			"Darkened world chorus STILL",
			"Zen's Symphony STILL",
			"Parallel Star STILL",
			"Winds of Fate STILL",
			"Soar STILL",
			"Morning Dew STILL",
			"Spelunking STILL",
			"Drops of Eden STILL",
			"Grand Adventure STILL",
			"Desert Moon STILL",
			"Luminous Depths STILL",
			"Night Flight STILL",
			"Dewdrops STILL", 
			"Off-road Memories STILL", 
			"Desert Dances STILL", 
			"Noise Gates STILL", 
			"Palm Winds STILL", 
			"Calm Winters STILL",
			"Opening Time STILL", 
			"Solace Spaces STILL",
			"Future Nostalgia STILL",
			"Light Nights STILL"
		],
		"gumroadLink": "https://gumroad.com/l/deepzen?wanted=true",
		"gumroadLinkWithBonus": "https://gumroad.com/l/dz-A17t?wanted=true",
		"imgFileName": "stillness-collection.jpg",
		"price": 39
	},		

	"serenity": {
		"pageID": "serenity",
		"tag": "stress", // only show testimonials that include this tag, leave blank to show all testimonials

		"title": "The Serenity Collection",

		"marketingTitle": "When you need targeted relief for anxiety",

		"shortDescription": "The Serenity collection can help get you through sudden anxiety attacks or help you through long term/chronic stress.",

		"description": "Get a deep sense of peace at the push of a button. Decades of neuroscience research packaged into music that soothes stress from your mind, muscles, and soul.",
		"benefitList": [
			"Get \"Xanax through your ears\" relaxation",
			"Have \"stress relief in your pocket\" for **go-anywhere relief**",

		],
		"comesWith": [
			"740 minutes of content, Equivalent to 247 regular pieces or 11.4 full length albums",
			"31 total tracks",
			"Pre-built playlists for iTunes and Windows Media Player",
			"EVERY new track we create for anti-stress (roughly two 20 minute pieces per month)"
		],
		"trackList":[
			"Eagle Rock ZEN (classic rock style)",
			"Breath of the Earth ZEN",
			"Bells ZEN",
			"Mellodrop ZEN",
			"Dark Mellodrop ZEN",
			"Spacewalk ZEN",
			"Dark spacewalk ZEN",
			"Moan of the earth ZEN",
			"Darkened moan of the earth ZEN",
			"World chorus ZEN",
			"Darkened world chorus ZEN",
			"Winds of Fate ZEN",
			"Zen's Symphony ZEN",
			"Soar ZEN",
			"Morning Dew ZEN",
			"Spelunking ZEN",
			"Drops of Eden ZEN",
			"Grand Adventure ZEN",
			"Desert Moon ZEN",
			"Luminous Depths ZEN",
			"Night Flight ZEN",
			"Dewdrops ZEN", 
			"Off-road Memories ZEN", 
			"Desert Dances ZEN", 
			"Noise Gates ZEN", 
			"Palm Winds ZEN", 
			"Calm Winters ZEN",
			"Opening Time ZEN", 
			"Solace Spaces ZEN",
			"Future Nostalgia ZEN", 
			"Light Nights ZEN"

		],
		"demos" : [ // NEED TO ADD DEMOS
			{
				file: "zenstant-3min-no-voice-demo.mp3",
				title: "Breath of the Earth ZEN"
			},
		],
		"gumroadLink": "https://gumroad.com/l/serenity-collection",
		"imgFileName": "serenity-collection.jpg",
		"price": 35
		

	},




/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////                                   ///////////////////////////////////
/////////////////////////////////// STUFF BELOW IS NOT CURRENTLY USED ///////////////////////////////////
///////////////////////////////////                                   ///////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////






	
	"unwind": {
		"abTest" : true, // used to hide from main products page
		
		"pageID": "unwind",
		"title": "Unwind PM",
		"tag": "sleep", // only show testimonials that include this tag, leave blank to show all testimonials
		
		
		"marketingTitle": "The fastest drug-free relief for insomnia, guaranteed.",
		
		"shortDescription": "Nearly 3 hours of NASA researched audio to overcome insomnia",

		"description": ""
		
			,
		"benefitList": [
			"Like a fistfull of Ambien (without the side effects)",
			"Get an instant \"recharge\" with FREE bonus \"AussieNap\"",
			"Reduce anxiety with the FREE bonus \"Light World Chorus ZEN\"",

		],
		"comesWith": [		
			"11 total audio tracks so that you can listen to a new one each night",
			"165+ minutes of content (equivalent to 55+ regular length pieces of music) so you can find rest even on the worst nights",
		],
		"demos": [
			{
				file: "Light Nightingales Forest PM-demo.mp3",
				title: "Light Nightingale's Forest PM"
			},
			{
				file: "Dark Symphony Of The Tides-demo.mp3",
				title: "Dark Symphony Of The Tides"
			},
			{
				file: "On The Strings Of An Angel PM demo.mp3",
				title: "On The Strings Of An Angel PM"
			},
			
		],
		"trackList":[
			"Dark Nightingale's Forest PM",
			"Light Nightingale's Forest PM",
			"On The Strings Of An Angel PM",
			"Strings Of The Fallen Angel PM",
			"Dark Calm's Cavern",
			"Light Calm's Cavern",
			"Dark Symphony Of The Tides",
			"Light Symphony Of The Tides",
			"FloatAway PM"
		],
		"gumroadLink": "https://gum.co/unwind-with-bonus?wanted=true",
		"imgFileName": "unwind-pm.jpg",

		"price": 46
	},

	"zenstantEssentials": {
		"abTest" : true, // used to hide from main products page

		"pageID": "zenstant-essentials",
		"title": "Zenstant ESSENTIALS",
		
		// "marketingTitle": "Get targeted menstrual relief in minutes for any symptom or your money back.",
		"marketingTitle": "The fastest drug-free menstrual relief for insomnia, productivity, and general pain(that won't take up room in your purse).",
		
		//"marketingSubtitle": "Zenstant COMPLETE is everything you could need for drug-free menstrual relief including solutions for cramps, insomnia, low/no desire to get out of bed, low energy, difficulty focusing/lack of productivity, headaches, nausea, and irritability.",
		//"marketingSubtitle": "The best of neuroscience and music combine to deliver this complete, drug-free solution to menstrual cramps.",
		
		"shortDescription": "The minimum esseitials that **you need for drug-free menstrual relief** including solutions for: cramps, insomnia, difficulty focusing/lack of productivity, and headaches.",

		"description": 
			"Zenstant ESSENTIALS is the minimum esseitials that **you need for drug-free menstrual relief** including solutions for: \n- cramps, \n- insomnia, \n- difficulty focusing/lack of productivity, \n- headaches." +
			"\n\n" + 
			"It combines decades of our proprietary auditory neuroscience research with music to deliver relief that goes anywhere your phone goes - no pills required." +
			"\n\n" +
			"**It might sound crazy, but it works.**"
			,
		"benefitList": [
			"Get the ability to calm your mind before bed that can help even an insomniac rest deeply",
			"An \"instant switch\" that you can use to gain an hour of intense productivity (even while in pain)",
			"General pain relief in your pocket that goes wherever your phone goes (no extra stuff to steal room in your purse)."
		
		],
		"comesWith": [
			"3 total audio tracks",
			"100+ minutes of audio content (equivalent to over 33 regular length pieces of music)",
			"Targeted solutions for pain, insomnia, and increasing productivity"
		],
		"demos": [
			{
				file: "zenstant-3min-no-voice-demo.mp3",
				title: "Zenstant SOLO"
			},
			{
				file: "electrofocus-demo-5min.mp3",
				title: "ElectroFocus"
			}
		],
		"trackList":[
			
			"Breath of the Earth PLUS",
			"FloatAway PM",
			"ElectroFocus (hour long productivity track)",
		],
		"gumroadLink": "https://gum.co/WQFrl?wanted=true",
		"imgFileName": "zenstant-essentials.jpg",

		"price": 27
	},

// marker to help me find zenstantSolo to lift copy

	"zenstantSolo": {
		"abTest" : true, // used to hide from main products page

		"pageID": "zenstant-solo",
		"title": "Zenstant SOLO",
		"marketingTitle": "When you want pain relief fast, from anywhere.",
		"shortDescription": "The original neuroscience audio for pain. 24 minutes of go-anywhere relief.",
		
		"description": 
			"Get relief from chronic and acute pains in minutes - whenever and wherever they strike. It combines decades of our proprietary auditory neuroscience research with music to deliver relief that goes anywhere your phone goes - no pills required." +
			"\n\n" +
			" >\"I'm honestly shocked that this worked!\" \n\n "
			,
		"benefitList": [
			"Get the ability to rapidly **take pain from \"stabbing\" to barely noticeable in minutes**",
			"24 minutes of pain distracting, deeply calming audio",
			"Pain relief in your pocket that goes wherever your phone goes",
			"A truly drug-free solution to pain"
		],
		"comesWith": [
			"One, 24 minute audio track",
		],
		"demos" : [
			{
				file: "zenstant-3min-no-voice-demo.mp3",
				title: "Zenstant SOLO"
			},
		],

		"gumroadLink": "https://gumroad.com/l/zenstant?wanted=true",
		"imgFileName": "zenstant-single.jpg",
		"price": 7.92
	},
	"zenstantComplete": {
		"abTest" : true, // used to hide from main products page

		"pageID": "zenstant-complete",
		"title": "Zenstant COMPLETE",
		
		"marketingTitle": "Get targeted menstrual relief in minutes for any symptom or your money back.",
		// "marketingTitle": "The fastest drug-free menstrual relief (that won't take up room in your purse).",
		
		//"marketingSubtitle": "Zenstant COMPLETE is everything you could need for drug-free menstrual relief including solutions for cramps, insomnia, low/no desire to get out of bed, low energy, difficulty focusing/lack of productivity, headaches, nausea, and irritability.",
		//"marketingSubtitle": "The best of neuroscience and music combine to deliver this complete, drug-free solution to menstrual cramps.",
		
		"shortDescription": "Everything you could need for drug-free menstrual relief including solutions for cramps, insomnia, low/no desire to get out of bed, difficulty focusing, headaches, nausea, and irritability.",

		"description": 
			"Zenstant COMPLETE is **everything you could need for drug-free menstrual relief** including solutions for: \n- cramps, \n- insomnia, \n- low/no desire to get out of bed, \n- low energy, \n- difficulty focusing/lack of productivity, \n- headaches, \n- nausea, and \n- irritability." +
			"\n\n" + 
			"It combines decades of our proprietary auditory neuroscience research with music to deliver relief that goes anywhere your phone goes - no pills required." +
			"\n\n" +
			"**It might sound crazy, but it works.**"
			,
		"benefitList": [
			"11 audio tracks to rapidly **make cramps go from stabbing pain to barely noticeable** (in minutes)",
			"The ability to calm your mind before bed *that can help even an insomniac* rest deeply",
			"A simple way to help you **be super productive** *(even with a headache)*",
			"A 20 minute 'recharge' track that helps induce catnaps for abundant energy (WAY better than caffeine)",
			"Eight, 30-minute pain reducing tracks in an ambient style to give you variety",
			"Ways to improve motivation in the morning when you just don't feel like getting out of bed",
			"**Pain relief in your pocket** that goes wherever your phone goes (no extra stuff to steal room in your purse)",
			"280+ minutes of neuroscience backed audio content delivering a truly _drug-free solution_ to menstrual pain",
			"8 tracks specifically designed to solve insomnia",
		],
		"comesWith": [
			"14 total audio tracks",
			"460+ minutes of audio content (equivalent to 153 regular length pieces of music)",
			"Targeted solutions for pain, insomnia, recharging energy, and increasing productivity",
			"Tracks categorized into 'albums' based on function, not marketing names"
		],
		"demos": [
			{
				file: "zenstant-3min-no-voice-demo.mp3",
				title: "Zenstant SOLO"
			},
		],
		"trackList":[
			"The entire 'PLUS' library of pain reducing neurosongs",
			"Eagle Rock PLUS (classic rock style)",
			"Breath of the Earth PLUS",
			"Bells PLUS",
			"Mellodrop PLUS",
			"Dark mellodrop PLUS",
			"Spacewalk PLUS",
			"Dark spacewalk PLUS",
			"Moan of the earth PLUS",
			"Darkened moan of the earth PLUS",
			"World chorus PLUS",
			"Darkened world chorus PLUS",
			"AussieNap (for fast naps)",
			"The entire 'PM' library of insomnia busting neurosongs, including:",
			"FloatAway PM",
			"ElectroFocus (hour long productivity track)",
			"Dark Nightingale's Forest PM",
			"Light Nightingale's Forest PM",
			"On The Strings Of An Angel PM",
			"Strings Of The Fallen Angel PM",
			"Dark Calm's Cavern",
			"Light Calm's Cavern",
			"Dark Symphony Of The Tides",
			"Light Symphony Of The Tides",
		],
		"gumroadLink": "https://gumroad.com/l/zenstantcomplete?wanted=true",
		// "imgFileName": "menstural-superpack.jpg",
		"imgFileName": "zenstant-complete.jpg",

		"price": 97
	},

	"zenstantComplete2": { // CONTENT HERE IS FOR AB TESTING, WILL NOT SHOW ON MAIN PRODUCTS PAGE
		"abTest": true,
		"pageID": "zenstant-complete-2",
		"title": "Zenstant COMPLETE",
		
		//"marketingSubtitle": "The best of neuroscience and music combine to deliver this complete, drug-free solution to menstrual cramps.",
		"marketingTitle": "Get targeted menstrual relief in minutes for any symptom or your money back.",
		// "marketingTitle": "The fastest drug-free menstrual relief (that won't take up room in your purse).",
		
		//"marketingSubtitle": "Zenstant COMPLETE is everything you could need for drug-free menstrual relief including solutions for cramps, insomnia, low/no desire to get out of bed, low energy, difficulty focusing/lack of productivity, headaches, nausea, and irritability.",
		
		"shortDescription": "Everything you could need for drug-free menstrual relief including solutions for cramps, insomnia, low/no desire to get out of bed, difficulty focusing, headaches, nausea, and irritability.",


		"description": 
			"Zenstant COMPLETE is **everything you could need for drug-free menstrual relief** including solutions for: \n- cramps, \n- insomnia, \n- low/no desire to get out of bed, \n- low energy, \n- difficulty focusing/lack of productivity, \n- headaches, \n- nausea, and \n- irritability." +
			"\n\n" + 
			"It combines decades of our proprietary auditory neuroscience research with music to deliver relief that goes anywhere your phone goes - no pills required." +
			"\n\n" +
			"**It might sound crazy, but it works.**"
			,
		"benefitList": [
			"11 audio tracks to rapidly **make cramps go from stabbing pain to barely noticeable** (in minutes)",
			"The ability to calm your mind before bed *that can help even an insomniac* rest deeply",
			"A simple way to help you **be super productive** *(even with a headache)*",
			"A 20 minute 'recharge' track that helps induce catnaps for abundant energy (WAY better than caffeine)",
			"Eight, 30-minute pain reducing tracks in an ambient style to give you variety",
			"Ways to improve motivation in the morning when you just don't feel like getting out of bed",
			"**Pain relief in your pocket** that goes wherever your phone goes (no extra stuff to steal room in your purse)",
			"280+ minutes of neuroscience backed audio content delivering a truly _drug-free solution_ to menstrual pain"
		],
		"comesWith": [
			"14 total audio tracks",
			"340+ minutes of audio content (equivalent to 114 regular length pieces of music)",
			"Targeted solutions for pain, insomnia, recharging energy, and increasing productivity",
			"Tracks categorized into 'albums' based on function, not marketing names"
		],
		"demos": [
			{
				file: "zenstant-3min-no-voice-demo.mp3",
				title: "Zenstant SOLO"
			},
		],
		"trackList":[
			"The entire 'PLUS' library of pain reducing neurosongs",
			"Eagle Rock PLUS (classic rock style)",
			"Breath of the Earth PLUS",
			"Bells PLUS",
			"Mellodrop PLUS",
			"Dark mellodrop PLUS",
			"Spacewalk PLUS",
			"Dark spacewalk PLUS",
			"Moan of the earth PLUS",
			"Darkened moan of the earth PLUS",
			"World chorus PLUS",
			"Darkened world chorus PLUS",
			"AussieNap (Catnap)",
			"FloatAway PM",
			"ElectroFocus (hour long productivity track)",
		],
		//"gumroadLink": "https://gumroad.com/l/zenstant/?wanted=true&variant=Targeted%20symptom%20upgrade%20%284%20extra%20hours%20of%20content%29",		
		"gumroadLink": "https://gumroad.com/l/zenstantcomplete/?wanted=true",
		// "imgFileName": "menstural-superpack.jpg",
		"imgFileName": "zenstant-complete.jpg",

		"price": 24
	},

}