import React from "react";
import styled from "styled-components"; 
import SetGumroadLink from "./setGumroadLink"; // required params: [0] gumroad URL and [1] discount code
import GetDiscountedPrice from './getDiscountedPrice';


// Props
	// self (REQUIRED): Set to 'this' in parent
	// upgradeUrl (REQUIRED); Set to Gumroad's upgraded upgradeUrl
	// newFullPrice
	// newDiscountedPrice
	// discountCode

const OrderFormBump = (props) => {


	return(
		<>
			<Container>
				<InputAndLabelContainer>
					<input id="upgradeCheckbox" type="checkbox" onChange={() => OrderFormBumpLogic(props.self, props.upgradeUrl, props.newFullPrice, props.newDiscountedPrice, props.discountCode)} defaultChecked={props.self.state.orderBumpChecked}/>
					{props.label &&
					<Label className="h5" htmlFor="upgradeCheckbox">
						{props.label}
					</Label>
					}
				</InputAndLabelContainer>
				<InputTextWrap>
					{props.children}
				</InputTextWrap>
			</Container>
		</>
	)
}


const OrderFormBumpLogic = (self, upgradeUrl, newFullPrice=null, newDiscountedPrice=null, discountCode=null) => { // not sure if I need this but putting it here for now
	let checkboxIsChecked = !self.state.orderBumpChecked;
	self.setState({orderBumpChecked: checkboxIsChecked}) // handle orderBump check

	if (checkboxIsChecked){// check to see if box is checked  // HANDLE USER CHECKING THE BOX
		if (!self.state.gumroadLinkNoBump){// if gumroadLinkNoBump isn't set... 
			self.setState({gumroadLinkNoBump: self.state.gumroadLink})// set gumroadLinkNoBump to old link
		}
		if (!self.state.gumroadLinkWithBump){// if gumroadLinkWithBump isn't set...
			let orderBumpGumroadLink = SetGumroadLink(upgradeUrl, discountCode, true /*reinitialize Gumroad*/); // set orderBumpGumroadLink to the returned value of SetGumroadLink(upgradeUrl) to add discount code
			self.setState({
				gumroadLinkWithBump: orderBumpGumroadLink, // this.state.gumroadLinkWithBump to orderBumpGumroadLink
				gumroadLink: orderBumpGumroadLink // set this.state.gumroadLink to orderBumpGumroadLink
			})	
		  }else{
		  	self.setState({gumroadLink: SetGumroadLink(self.state.gumroadLinkWithBump, discountCode, true)})// else set this.state.gumroadLink to this.state.gumroadLinkWithBump
		}
		if (newDiscountedPrice){
			self.setState({
				discountedPrice : newDiscountedPrice
			})
		}							
		// if discountedPriceNoBump isn't set AND discountedPrice IS set
			// set state of discountedPriceNoBump to this.state.discountedPrice
		  // else if discountedPrice is set, set this.state.discountedPrice to newDiscountedPrice

		if(!self.state.regularPriceNoBump){ // if regularPriceNoBump isn't set...
			self.setState({regularPriceNoBump: self.state.regularPrice}) // set state of regularPriceNoBump to this.state.regularPrice;
		}
		self.setState({regularPrice: newFullPrice}) // set this.state.regularPrice to newFullPrice
		
		console.log("Checkbox was checked")

	}
	if (!checkboxIsChecked){// check to see if user unchecked the box
		self.setState({ 		// set states below:
			regularPrice: self.state.regularPriceNoBump,
			discountedPrice: GetDiscountedPrice(), // set to URL based discounted price
			gumroadLink: SetGumroadLink(self.state.gumroadLinkNoBump, undefined /* defaults to url based discount code (param is 'dc=')*/, true ),
		})
		console.log("Checkbox was unchecked")
	}
} 

const Container = styled.div`
	padding: 10px;
	background-color: #ffffdd;
	margin-bottom: 10px;
`

const InputAndLabelContainer = styled.div`
	display: inline-flex;

	& input {
		height: 1.25rem;
    	width: 1.25rem;
    	margin-top: 0.25rem;
    }
`

const InputTextWrap = styled.div`
`

const Label = styled.label`
	font-weight: 900;
    
`


export default OrderFormBump;
