import React from "react";

export const LoadGoogleOptimize = () => {
  const script = document.createElement("script");
  script.src = "https://www.googleoptimize.com/optimize.js?id=OPT-TCR7HZB";
  script.id = "google-optimize";
  script.async = true;
  document.body.appendChild(script);

  script.onload = () => { 
    console.log("Google Optimize loaded")
    return(true);
  };
}