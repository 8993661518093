import React from 'react';
import { hydrate, render } from "react-dom";

import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';

import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'

import {variantCopy} from "./templateCopyText.js";
import checkURL from './scripts/checkURL';
import {GetUrlParam} from './scripts/getUrlParam';
import SetGumroadLink from './scripts/setGumroadLink';




const currentURL = checkURL(); 
// const googleAnalyticsID = variantCopy[checkURL()].gaID; // Call the checkURL function which checks the current URL to be used with templateCopyText to get template specific content. Then get Google analytics ID.
let trackingPixelDebug = false; // Init, don't change (use trackingPixelDebugManual)

// Google analytics - replacing with Google Tag Manager
// ReactGA.initialize(googleAnalyticsID, { // Initialize Google analytics with the ID found above
//   debug: trackingPixelDebug,
//   titleCase: false,
//   gaOptions: {
//     siteSpeedSampleRate: 100
//   }
// });

 

// Facebook pixel/tracking
const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
    autoConfig: true,   // set pixel's autoConfig
    debug: trackingPixelDebug,     // enable logs
};
ReactPixel.init('1503333016585016', advancedMatching, options); // Limbic standard pixel


// Google Tag Manager init
const initTagManager = () =>{
  if (!window.PageDataLayer){ // Check to see that the dataLayer does not exist, if not, init GTM
    const tagManagerArgs = {
      'gtmId': 'GTM-NKBQ6N7',
      dataLayerName: 'PageDataLayer'
    }

    TagManager.initialize(tagManagerArgs);
  }
}

if (!window.PageDataLayer){initTagManager()};  // Only init if needed... BROKEN (will still init several GTMs... at least that's what GTM helper says)

if (typeof window !== "undefined") {
  const affiliateId = GetUrlParam("affiliate_id");
  if(affiliateId){const gumLink = SetGumroadLink()}; // Load gumroad to cookie the browser with the affiliate ID
}

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
