import React from "react";
import {GetUrlParam} from "../../scripts/getUrlParam";

const dynamicHeaderText = GetUrlParam("h"); // Look to see if there is a url param of "h".

const DynamicH1 = props => {

	let text = props.text;

	if (!text){
		text = props.template.heading;
	}
	
	return(
		<h1 className={props.classes} style={props.style}>
			{dynamicHeaderText 				// If 'h' url param exists...
				? dynamicHeaderText 			// set the h1 text to the text set by the 'h' URL param.
				: text 		// otherwise, return the regular text
			}
		</h1>
	)
}; export default DynamicH1;