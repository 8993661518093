import React from "react";

const SetGumroadLink = (
  inputGumUrl="https://gumroad.com/l/zenstantcomplete?wanted=true", 
  discountCode = null, 
  reinitialize=false, 
  sendToGumroad=false
) => {


  let url = window.location.href; // get current url
  const urlParams = new URLSearchParams(window.location.search);
  const entries = urlParams.entries(); 
  let link;


  try {
    let 
      dc, 
      variant = "";
    if(discountCode){
       dc = discountCode;
    } else {
      dc = urlParams.get('dc'); // check for "dc=" (discount code)
      if (!dc){dc=""} // in case dc is null, set to an empty string
    }

    let v = inputGumUrl.split("variant=")[1]; 
    if (v){
      variant = "variant=" + v.split("&")[0];
    }    // then make sure there are no other params

    let wanted = "wanted=true&"; // sends users directly to the gumroad checkout page
    if (sendToGumroad){
      wanted = ""
    }

    // Check for affiliate IDs, if there is one alter "dc" variable to the proper Gumroad ID setup
    let affiliateId = urlParams.get('referenceid') // check for 'referenceid' - the affiliate ID
    if (affiliateId && dc){ // If both affiliateId and discountCode exist...
      dc= "a/" + affiliateId + "?offer_code=" + dc + "&" // Due to Gumroad's design, to combine affiliate IDs and discount codes, gumroad URL needs to look like: /a/{affiliateId}?offer_code={discountCode}
    } else if((!affiliateId || affiliateId === "") && dc) {dc = dc + "?"} // if no affiliate ID but has discount code
    else if(affiliateId && !dc){ // if Affiliate ID but no Discount code
      dc= "a/" + affiliateId + "?"
    } else if (!dc && (wanted || variant)) { // If no affiliate ID and no discount code but other params exist
      dc = "?"
    } else {
      dc = ""
    }
    



    // Reconstruct URL to include special Gumroad parameters    
    link = inputGumUrl.split("?wanted=true")[0] + "/" + dc + wanted + variant; // Create link and include the discount code
    
  } catch {
    link = inputGumUrl; // otherwise, just return the standard gumroad link
  }
  if (!link){let link = inputGumUrl}

  if (!document.getElementById("gumroad-script") || reinitialize){ // check that gumroad isn't initialized already
    console.log("Initializing Gumroad...")
    initializeGumroadScript(); // Loads the gumroad script for pages that need checkout
  }


  return link;
};  


const initializeGumroadScript = () => {
  // let gumScriptId = document.getElementById("gumroad-script")
  // if (gumScriptId){ // if a script already exists or we expressly called to reinit
  //   gumScriptId.parentNode.removeChild(gumScriptId); // remove the script so we can re-create it again
  //   console.log("Gumroad reloaded in init script");
  // }
  const script = document.createElement("script");
  script.src = "https://gumroad.com/js/gumroad.js";
  script.id = "gumroad-script";
  script.async = true;
  document.body.appendChild(script);

  script.onload = () => { 
    console.log("Gumroad loaded")
    return(true);
  };

  
}

export default SetGumroadLink;


