// Homepage-like. Testing new demo experience to improve demo->AtC


import React, { PureComponent, Suspense, lazy } from "react";
import {variantCopy} from "../templateCopyText.js";
// import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';

import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {LinkWithQuery} from '../modules/linkWithQuery';
import Image from '../scripts/image';

import Track from "../scripts/track";


import DemoButton from '../modules/zenstant/demoButton'

import ZenstantSalesCopy from '../modules/zenstant/zenstantSalesCopy';
import GrantList from '../modules/zenstant/grantList';
import SocialProof from '../modules/zenstant/socialProof';
//import FullTestimonials from '../modules/fullTestimonials';

// import TheScience from '../modules/zenstant/theScience';
// import SatisfactionGuarantee from '../modules/zenstant/satisfactionGuarantee';
import FAQ from '../modules/zenstant/faq';
import Footer from '../modules/zenstant/footer';


// NOT FOUND ON REGULAR HOMEPAGE
import DynamicH1 from "../modules/zenstant/dynamicH1";
import {GetIco} from "../scripts/getIco";
import {WatchForUsersFirstScroll} from "../scripts/initialScrollWatcher";

import {ShowPlayer} from "../modules/zenstant/showPlayer";

// const TheScience = lazy(() => import ('../modules/zenstant/theScience'));
const FullTestimonials = lazy(() => import ('../modules/fullTestimonials'));
// const TheBenefits = lazy(() => import('../modules/zenstant/theBenefits'));
const TheBenefitsStandardImplementation = React.lazy(
  () => import('../modules/zenstant/theBenefits').then(module => ({ default: module.TheBenefitsStandardImplementation })));
const TheScienceStandardImplementation = React.lazy(
  () => import('../modules/zenstant/theScience').then(module => ({ default: module.TheScienceStandardImplementation })));
const SatisfactionGuarantee = lazy(() => import('../modules/zenstant/satisfactionGuarantee'));



// Template for integrating FB pixel events and info;
// ReactPixel.pageView();          // For tracking page view
// ReactPixel.track( event, data )     // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
// ReactPixel.trackCustom( event, data )   // For tracking custom events






class App extends PureComponent {
  constructor(props) {
    super(props);

    const thisURL = "zenstant";
    
    this.state = {
      phone: "",
      getDemo: false,
      audioFile: null,
      isPlaying: false,
      playerStarted: false,
      leftClasses: "cell small-12 medium-8",
      whichTemplate: thisURL, // this references the templateCopyText.js file, which contains all copywriting, color schemes, etc.
      gumroadLink: "",
      discountedPrice: null,
      leadLogged: false,
      fullPrice: "$7.92",
      bgVidSrc: null, // start null so that the poster is shown, replace after mounting
      gumroadMainCtaText: "",
      componentMounted: null,
      bgImg: "cheerful-woman-using-zenstant-for-period-pain-duotone-light.jpg",
      userScrolled: false,
    };
    this.logLead = this.logLead.bind(this);
    this.audioPlayer = React.createRef();
    document.title = variantCopy[thisURL].title;
    ReactPixel.pageView(); // track pageview
    // ReactGA.pageview(window.location.pathname + window.location.search); // Tack pageview in GA
  }


  componentDidMount(){
    WatchForUsersFirstScroll(this); // use to watch for user's first scroll. On scroll, state of userScrolled will be set to true.
    GetIco();
  }



  componentDidUpdate(){}



  

  resetVars = (template) => {
    if (this.state.isPlaying === true){
      this.playPause(template);
    }

    this.setState({
      isPlaying: false,
      playerStarted: false,
      getDemo: false
    })

    this.leftSideContent(template);
    
  }


  leftSideContent = (template) => {
    if (this.state.getDemo){

      return(
        ShowPlayer(this, template)
      )
    } else {
      return(
        
        <div id="main-left-content" className="grid-x" style={{textAlign:"left", zIndex: 2}} >
          <div className="small-12 medium-8 cell">
            <DynamicH1 template={template}  />
          </div>
          <div className="cell small-12 medium-8" style={{marginBottom: "7.5vh"}}>
              <h3>
                {template.mainText}
                {/*Wouldn't it be nice to get relief from exhaustion, stress, cramps, headaches, insomnia, and even nausea anywhere you have your phone? 
                                <br/><br/>
                                Zenstant is 20 years of NASA-backed neuroscience research packed into the world's simplest delivery: music. It sounds crazy, but it works.*/}
                
              </h3>
          </div>
          <div className="small-12 cell">
            <div id="main-pg-cta" className="grid-x grid-margin-x">
              <LinkWithQuery
                className="button small-12 medium-auto cell" 
                to="/free" >Get a full year of Brain Audio 100% free
              </LinkWithQuery>
              <LinkWithQuery className="small-6 medium-8 large-9 cell" style={{alignItems: "center", display: "flex"}} to="/products" >Or see relief packages</LinkWithQuery>
              

              {/*<DemoButton link getDemo={this.getDemo} playPause={this.playPause} buttonClasses="small-6 medium-8 large-9 cell" words="Or try a demo"/>*/}
            </div>
          </div>

          
        </div>
        
      )
    }
  }




  // Fuction fires to allow user to play, or pause, music.
  playPause = (template = variantCopy[this.state.whichTemplate]) => {

    if (this.audioPlayer.current.paused) {
      this.audioPlayer.current.play();
      this.setState({        // set state
        isPlaying: true,     // of playing to true 
        playerStarted: true  // and player started to true
      });

      Track(template /*template*/, 'ViewContent' /*category*/, 'PlayDemo' /*name (FB)/action(GA)*/, undefined /*value*/, false/*is custom FB event*/);

    } else {
      this.audioPlayer.current.pause();
      this.setState({ isPlaying: false });
      Track(template /*template*/, 'ViewContent' /*category*/, 'PauseDemo' /*name (FB)/action(GA)*/, undefined /*value*/, false/*is custom FB event*/);
    }
  };

  logLead = (template = variantCopy[this.state.whichTemplate], value=null) => {
    // If user clicks "buy" for the first time in a session, log an 'add to cart' event
    if (!this.state.leadLogged){
      this.track(template, 'AddToCart' /*category*/, 'clicked-zenstant-painblock-gumroad-button' /*name (FB)/action(GA)*/, value )
      this.setState({leadLogged: true})
    }
  }


  // Function 'this.track' (below) was only kept because there are still some references for it.
  track = (template = variantCopy[this.state.whichTemplate], category = "Lead", name = "Clicked Zenstant remind me later", value = undefined) => {
      Track(template /*template*/, category /*category*/, name /*name (FB)/action(GA)*/, value /*value*/, false/*is custom FB event*/);
  }


  getDemo = () => {
    this.loadMedia(); // load the audio
    this.setState({ 
      getDemo: true,
      gumroadMainCtaText: "Like it? Get the full 23 min track for "
    });

  };


  loadMedia = () => { // Grabs media when ready to avoid long download times
    fetch('/assets/zenstant-3min-no-voice-demo.mp3')
      .then(data => {
        this.setState({audioFile: data.url});
      })
  }




  render(){
    const template = variantCopy[this.state.whichTemplate];
    const logoFilePath = '/assets/' + template.logo;
    const icoFilePath = '/assets/' + template.ico + '.ico';

    return (
      <>
        <Helmet>
            <title>Zenstant: Push play, get relief</title>
            <link rel="canonical" href="https://www.zenstant.co" />
            <link id='ico' rel="icon"  href={'/assets/' + template.ico + '.ico'}/>
            <meta name="description"     content="Get Zen in an Instant with Zenstant" />
            <meta property="og:url"                content="https://www.zenstant.co/" />
            <meta property="og:type"               content="website" />
            <meta property="og:title"              content="Relief for pain, anxiety, distraction, and exhaustion in minutes (no drugs)" />
            <meta property="og:description"        content="Get relief through your smartphone. Seriously. Free demo." />
            <meta property="og:image"              content="https://www.zenstant.co/assets/zenstant-ogimg-default.png?v=2" />
            <meta property="og:image:width"        content="940" />
            <meta property="og:image:height"        content="788" />
            <meta property="og:image:alt"    content="A woman getting relief with Zenstant" />
        </Helmet>

        <div id="App" className="grid-container fluid">
          <div id="main-container" className="grid-x grid-padding-x" style={{minHeight:"0"}}>          
              <div id="left-main-container" className="small-12 cell" style={{ backgroundImage:`url(/assets/${this.state.bgImg})`, backgroundSize:"cover", backgroundPosition:"top right", backgroundRepeat:"no-repeat", paddingTop: "16vh", paddingBottom: "20vmin"}} >
                
                <div id="logo-container" className="">
                  <Image id="logo" className="" src={logoFilePath} alt={template.title} onClick={() => this.resetVars(template)} />
                </div>
                
                {this.leftSideContent(template)}

              </div>




            {this.state.audioFile &&  // Only load audio player once component is mounted (see componentDidMount) aboce
                        <audio
                          id="audioPlayer"
                          crossOrigin="anonymous"
                          loop
                          ref={this.audioPlayer}
                        >
                          <source src={this.state.audioFile} type="audio/mpeg" /> 
                        </audio>
            }

        </div>


          <SocialProof template={template}/>

          <Suspense fallback={<div>Loading...</div>}>
            <FullTestimonials numberOfTestimonials="3" singleLine template={template} widthOverride/> 
          </Suspense>
          
          {this.state.userScrolled &&
            <Suspense fallback={<div>Loading...</div>}>
              {/*<TheBenefitsStandardImplementation />*/}
              <TheScienceStandardImplementation template={template} />
              <SatisfactionGuarantee template={template} />
              <FullTestimonials id="full-testimonials-container" template={template} masonry />
              <FAQ />
              <SatisfactionGuarantee template={template} />
            </Suspense>

          }


          <Footer template={template} />
      </div>
     </>
    );
  }
}

export default App;

