import React from "react";
import Log from "../scripts/log";
import FileExists from "./fileExists";

// A script to create image files that use Webp and gracefully fall back on old, 
	// crappy filetypes (PNG, JPEG)

const Image = (props) => {
	let imgSource = props.src;
	let nakedSource = "";
	let webpSrc = "";
	let jpgSrc = "";
	let jpegSrc = "";
	let pngSrc = "";
	let pictureHtml = "";
	let imageType = checkImage(imgSource);

	if(imageType){

		nakedSource = imgSource.replace('.png', "").replace('.jpg', "").replace('.jpeg', "").replace('.webp', ""); // strip out all main filetypes from string

		if (imageType){
			webpSrc = nakedSource + ".webp";
			jpgSrc = nakedSource + ".jpg";
			jpegSrc = nakedSource + ".jpeg";
			pngSrc = nakedSource + ".png";

		}
		if(imageType != "svg"){ // If NOT svg, use <picture> tag with fallbacks
			pictureHtml = getPictureHtml(props, imageType, webpSrc, jpgSrc, jpegSrc, pngSrc);
		}
	}

	
	return(
		<>
			{(imageType === "svg") && // If svg, use regular tags and an SVG
			  <img src={nakedSource} alt={props.alt} style={props.style} className={props.className} onClick={props.onClick} />
			}
			{(imageType !== "svg") && pictureHtml}
			
		</>
	)
};

export default Image;


const checkImage = (imageInput) => { // Function checks image type, returns string of type or "false" for unsupported.
	let dumbassMessage = "I'm a hamburger!";

	if(imageInput.includes(".svg")){return ("svg");}  		// Image is an svg
	else if(imageInput.includes('.webp')){return "webp"} 	// Image is a png
	else if(imageInput.includes(".jpg")){return "jpg";} 	// Image is a jpg
	else if(imageInput.includes(".jpeg")){return "jpeg";} 	// Image is a jpeg
	else if(imageInput.includes(".png")){return "png";} 	// Image is a png
	else {return false};
	return dumbassMessage;
}



const getPictureHtml = (props, imageType, webpSrc, jpgSrc, jpegSrc, pngSrc, imgSource) =>{
	let webpFallbackSrc;
	let webpFallbackType = "";

	// Check to see which file types exist if the input file was webp.
	// this is a VERY heavy and blocking function... basically DON'T USE WEBP
	
	if(imageType === "webp"){
		if(FileExists(jpgSrc)){ // first check .jpg
			webpFallbackSrc = jpgSrc;
			webpFallbackType = "image/jpeg";
			Log("jpg exists!")
		}else if(FileExists(pngSrc)){ // first check .png
			webpFallbackSrc = pngSrc
			webpFallbackType = "image/png";
			Log("png exists!")
		}else if(FileExists(jpegSrc)){ // then check .jpeg
			webpFallbackSrc = jpegSrc;
			webpFallbackType = "image/jpeg";
			Log("jpeg exists!")
		}
	}

	return(
		<picture className={props.className} style={props.style} >
			{(imageType !== "svg") && // First, try the filename with .webp appended to it
				<source srcSet={webpSrc} type="image/webp" alt={props.alt} style={props.style} />
			}

			{(imageType === "jpg") && // If input was a jpg, create JPG fallbacks
				<>
					<source srcSet={jpgSrc} type="image/jpeg" style={props.style} alt={props.alt} /> 
					<img src={jpgSrc} style={props.style} alt={props.alt} />
				</>

			}
			{(imageType === "jpeg") && // If input was a jpeg, create JPEG fallbacks
				<>
					<source srcSet={jpegSrc} type="image/jpeg" style={props.style} alt={props.alt} /> 
					<img src={jpegSrc} style={props.style} alt={props.alt} />

				</>
			}
			{(imageType === "png") && // If input was a png, create PNG fallbacks
				<>
					<source srcSet={pngSrc} type="image/jpeg" style={props.style} alt={props.alt} /> 
					<img src={pngSrc} style={props.style} alt={props.alt} />
				</>
			}
			{(imageType === "webp") && // If input was a webp then use whatever filename ended up existing. Generally, DON'T INPUT WEBP!
				<>
					<source srcSet={webpFallbackSrc} type={webpFallbackType} style={props.style} alt={props.alt} className={props.className}/> 
					<img src={webpFallbackSrc} style={props.style} alt={props.alt} />
				</>
			}

		</ picture>
	)
	
}