import React, { Component, Suspense, lazy } from 'react';

// import ReactGA from "react-ga";
import {variantCopy} from "../templateCopyText.js";
import ReactPixel from 'react-facebook-pixel';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {LinkWithQuery} from '../modules/linkWithQuery';
import styled from 'styled-components';
import Track from "../scripts/track";
// import Image from "../scripts/image";
import Log from "../scripts/log";

import ReactMarkdown from 'react-markdown';

import HeaderAndNav from '../modules/zenstant/headerAndNav';
import Footer from '../modules/zenstant/footer';


import {productCopy} from "../productCopy.js";

import SocialProof from '../modules/zenstant/socialProof';
import FullTestimonials from '../modules/fullTestimonials';

import {TheBenefitsStandardImplementation} from '../modules/zenstant/theBenefits';
import {TheScienceStandardImplementation} from '../modules/zenstant/theScience';
import SatisfactionGuarantee from '../modules/zenstant/satisfactionGuarantee';
import UltimateGuarantee from '../modules/ultimateGuarantee';


import AbTestDesign from '../modules/zenstant/productAbTestDesign';

import GetDiscountedPrice from '../scripts/getDiscountedPrice'; 
import SetGumroadLink from '../scripts/setGumroadLink';


export default class Product extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			productID: "",
			productHref: "",
			theImgSrc: "",
			productInfo: productCopy[this.props.id],
			fullPrice: productCopy[this.props.id].price,
			typicalConversionRate: 0.02, // adjusts the value of a product page view (pageViewValue = price * typicalConversionRate)
		  
		};
		// ReactGA.pageview(window.location.pathname + window.location.search); // record this page on GA
		ReactPixel.pageView();          // Track pageview on FB
	}

	componentDidMount(){
		document.body.scrollTop = 0; // For Safari
  		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		this.getSpecificProduct(); // Sets HTML to the proper product
		this.logContentView();
	}

	

	getSpecificProduct = () => {
		const theGumroadHref = SetGumroadLink(productCopy[this.props.id].gumroadLink, null /*Discount Code*/, false /*Reinitialize*/, true /*Send to Gumroad*/ /* previously used 'productCopy[this.props.id].sendToGumroad' but having issues with loading the overlay*/);
		const theImgSrc = "/assets/productImages/" + productCopy[this.props.id].imgFileName;
		const discountedPrice = GetDiscountedPrice(); // checks for discounted price

		this.setState({
			productInfo: productCopy[this.props.id],
			productID: this.props.id,
			productHref: theGumroadHref,
			theImgSrc: theImgSrc,
			discountedPrice: discountedPrice,
		})
	}


	logContentView = () => {
		let contentName = "Product Page: " + productCopy[this.props.id].title;
		let value;
		if (this.state.discountedPrice){value = this.state.discountedPrice} else { value = this.state.fullPrice};
		value = value * this.state.typicalConversionRate;

		Track(undefined /*product template*/, 'ViewContent' /*category*/, contentName /*name (FB)/action(GA)*/, value /*value*/, false /*is custom FB event*/)
	}

	gumroadButton = (params = false) => { // Create gumroad purchase button. Takes object:key params of 'fullwidth' and 'words'
	    let gumButtonStyle = {};
	    let text;

	    let gumroadLink = productCopy[this.props.id].gumroadLink;
	    try {
	      text = params.words;
	    } catch {text = null}
	   
	    if (params.fullwidth) {
	        gumButtonStyle = {
	          width: "100%",
	      }
	    } else {
	      gumButtonStyle = {}
	    }

	    return(

	      <a  className="gumroad-button button" href={this.state.productHref} target="_blank" style={gumButtonStyle} >
	        {!text && <>Buy now</>} {text} 
	      </a>
	    );
	}

	productComesWith = () => {
		return(
			<>
				<h4>Included in your purchase:</h4>
				<ul>
					{this.state.productInfo.comesWith.map((item, index) => {
						return(
							<li key={index}>{item}</li>
						)
					})}
				</ul>
				<DemoContainer className="" >

					<h5 style={{fontWeight: 900}}>
						{(this.state.productInfo.demos.length > 1) ? "Select Demos" : "Demo"}
					</h5>
					<p><em style={{color: "#3a3a6a"}}>Nearly all of our neuroscience protocols require headphones to experience the effects</em></p>



					{this.state.productInfo.demos.map((song, index) => {
						return(
							<DemoInner key={index}>
								{(this.state.productInfo.demos.length > 1) &&  // If there are multiple demos...
									<>
										<p style={{marginBottom:"0"}}><strong> {song.title}:</strong></p> 
										{/*<p style={{marginBottom:"0"}}> {index + 1}:</p> */}
									</>
								}
								<AudioDemo
									id="audioPlayer"
									key={song.file}
									crossOrigin="anonymous"
									controls
									controlsList="nodownload"
									onPlay={() => Track(undefined /*template*/, 'ViewContent' /*category*/, 'PlayDemo' /*name (FB)/action(GA)*/, undefined /*value*/, false/*is custom FB event*/)}
								>
									<source src={'/assets/' + song.file} type="audio/mpeg" /> 
								</AudioDemo>
							</DemoInner>
						)
					})}


				</DemoContainer>
			</>
		)
	}

	styledPrice = () => {
		return(
				<>
					{this.state.discountedPrice 
						? <strike style={{color: "#000", fontWeight: "300"}}>$ {this.state.fullPrice}</strike> 
						: "$" + this.state.fullPrice}
					&nbsp;  
					{this.state.discountedPrice && "$" + this.state.discountedPrice}
				</>
			)
	}

	render(){
		let template = variantCopy.zenstant; // Feature creep... this allowed us to create several sites with the same formats, but isn't used. However it must be included or many things fall apart.
		
		return(
			<>
				<Helmet>
		            <title>{'Product ' + this.state.productInfo.title + ' by Zenstant'}</title>
		            
		            <link id='ico' rel="icon"  href={'/assets/' + template.ico + '.ico'}/>
		            <meta name="description"     content={this.state.cleanDescription}/>
		            <meta property="og:url"                content={"https://www.zenstant.co/" + window.location.pathname} />
		            <meta property="og:type"               content="website" />
		            <meta property="og:title"              content={this.state.productInfo.title} />
		            <meta property="og:description"        content={this.state.cleanDescription} />
		            <meta property="og:image"              content={"https://www.zenstant.co/assets/productImages/" + this.state.productInfo.imgFileName} />
		            <meta property="og:image:width"        content="940" />
		            <meta property="og:image:height"        content="788" />
		        </Helmet>
				<div className="grid-container fluid">
					<HeaderAndNav productPage purchaseText={"Buy now"} href={this.state.productHref} />

					{!this.props.abTest &&
						<>
					
							<ContentContainer className="grid-x grid-margin-x">
								{/*<Image src={this.state.theImgSrc} /> */} {/* UNCOMMENT this when webp images ready*/}
								{<LinkWithQuery to="/products" className="small-12 cell" style={{marginBottom: "1rem"}}> &#60; All products</LinkWithQuery>}
								<div className="small-12 medium-5 large-4 cell">
									<div className="show-for-small-only">
										<ProductTitle className="medium-h3">{this.state.productInfo.title}</ProductTitle>
										{this.state.productInfo.marketingTitle &&
											<MarketingTitle id="product-marketing-title" className="h4">
												 {this.state.productInfo.marketingTitle}
											</MarketingTitle>
										}
										<HR className="show-for-medium"/>

									</div>
									<Suspense fallback={<div>Loading image...</div>}>
										<img src={this.state.theImgSrc} />
									</Suspense>

									<div className="show-for-medium">
										{this.state.productInfo.comesWith && this.productComesWith()}						
									</div>
								</div>
								<div className="auto cell">
									<div className="show-for-medium">
										<ProductTitle className="medium-h3">{this.state.productInfo.title}</ProductTitle>
										{this.state.productInfo.marketingTitle &&
											<MarketingTitle id="product-marketing-title" className="h4">
												 {this.state.productInfo.marketingTitle}
											</MarketingTitle>
										}
										<HR className="show-for-medium"/>

									</div>
									<ReactMarkdown source={this.state.productInfo.description}/>
									<h4>You'll:</h4>
									<ul>
										{this.state.productInfo.benefitList && this.state.productInfo.benefitList.map((benefit, index) => {
											return(
												<li key={index}><ReactMarkdown source={benefit} /></li>
											)
										})}
									</ul>
									<div className="show-for-small-only">
										{this.state.productInfo.comesWith && this.productComesWith()}							
									</div>

									<div>
										Price: &nbsp;

										<Price>
											{this.styledPrice()}
										</Price>
									</div>
									
									<div onClick={() => Track(template, 'AddToCart' /*event type*/, this.state.productInfo.title /*event action (IE name))*/ , this.state.productInfo.price * 0.02, false)} >
						              {this.gumroadButton({words: "Buy now"})}
						            </div>
						            <em>All purchases protected by an 82 year guarantee and our "we'll remind you in 5 days to ask for a refund if you're not happy" promise.</em>

						            <br/>
						            <h4 style={{marginTop:"2.25rem"}}>Track List</h4>
						            <TrackList>
										{this.state.productInfo.trackList && this.state.productInfo.trackList.map((trackName, index) => {
											return(
												<li key={index}><ReactMarkdown source={trackName} style={{marginBottom: "0.5rem"}}/></li>
											)
										})}
									</TrackList>
								</div>
		      
								
							</ContentContainer>
							
							<SocialProof />

          					<FullTestimonials numberOfTestimonials="3" singleLine template={template} widthOverride tag={this.state.productInfo.tag} /> 

          					{/*<TheBenefitsStandardImplementation />*/}
						
		          			<SatisfactionGuarantee template={template} gumroadButton={this.gumroadButton({words: this.state.gumroadMainCtaText})} productInfo={this.state.productInfo} />

				            <TheScienceStandardImplementation template={template} />

							<FullTestimonials masonry id="full-testimonials-container" tag={this.state.productInfo.tag}/>

		          			<SatisfactionGuarantee template={template} gumroadButton={this.gumroadButton({words: this.state.gumroadMainCtaText})} productInfo={this.state.productInfo} />
		          		</>
	          		}

	          		{this.props.abTest && 
	          			<AbTestDesign 
	          				productInfo = {this.state.productInfo} 
	          				id = {this.props.id}

	          			/>
	          		}

					<Footer />

				</div>
			</>
		)
	}
}


const ContentContainer = styled.div`
	padding: 1.5rem;
	background-color: #F0F3FA;
`

const H1 = styled.h1`
	color: #F0F3FA;
	color: #F0F3FA;
	color: #343539;
	font-size:1.5rem;
	font-weight: 700;
`

const ProductTitle = styled.h1`
	margin-bottom: 0;
	padding-bottom: 0;
	font-weight: 900;
	// color: #FB637E;
	color: #213343;
`

const MarketingTitle = styled.h2`
	font-weight: 900;
`

const HR = styled.hr`
	margin-top: 0;
	margin-bottom: 0.75rem;
`

const DemoContainer = styled.div``

const DemoInner = styled.div`
	// display: inline-flex;
	align-items: center;
`


const AudioDemo = styled.audio``

const Price = styled.span`
	color: #B6351A;
	font-weight: 700;
	font-size: 110%;
`

const TrackList = styled.ol`
	p {
		margin-bottom: 0.25rem;
	}
`


