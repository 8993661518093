import React, { PureComponent } from "react";
import {variantCopy} from "../../templateCopyText.js";
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import Track from "../../scripts/track";
import ReactPixel from 'react-facebook-pixel';
import ReactMarkdown from 'react-markdown';

import {LoadGoogleOptimize} from "../../scripts/loadGoogleOptimize";

import HeaderAndNav from "../../modules/zenstant/headerAndNav";
import QuoteContainer from "../../modules/quoteContainer";


import DynamicH1 from "../../modules/zenstant/dynamicH1";

import {PlayPauseButton} from "../../scripts/audioPlayer";

import ConvertKitForm from "../../modules/convertKitForm";
import SetGumroadLink from '../../scripts/setGumroadLink';


import SocialProof from '../../modules/zenstant/socialProof';
import Footer from '../../modules/zenstant/footer';





// CTA-form-specific information (copy can be found within the constructor)
const uid = "b8e8290f3e";
const svForm = "2664759";

// Landing page copy
const theTitle = "Start meditating in 3 minutes flat"

const thePreHeadline = `
	NASA-backed neuroscientist will show you
`

const theHeadline = `
	How to meditate (even when you don't want to)
`

const theSubhead = `
	A science-backed meditation enhancer so good you'll meditate without your permission.
`

let supportingCopy;
/*
supportingCopy = `
Dear friend,

It all began with a bottle of sake and a question: "can we mess with the brain using music?"

Hi, I'm Garrett Dunham and the music you can listen to below is the lovechild of a world famous musician and an Ivy League professor of neuroscience with an IQ of 196.

This one question - "can we mess with the brain using music" - lead to strapping lasers to bat's heads, the only known allergy to bullfrog urine, and 40+ grants from NASA  and the National Institute of health (reality really is crazier than fiction).

The answer?

**Yes**

We can use music to influence the brain at a subconscious level.

Please enjoy free access to the track and if you like it, consider downloading it/joining our email list.

&dash; Garrett
`
*/

const formBodyContentInHTML = `
  <p>This course includes:</p>
 <ol>
 	<li><strong>Overcoming that "I know I need to meditate but I just don't feel like it" hump</strong>. (Or "How I broke a 7 year gym-drought with this 'five-minute' hack.")</li>
	<li><strong>How to meditate consistently without willpower</strong> though a Stanford behavior design professors dead-simple 'habit trick'.</li>
  <li><strong>Saying 'in-state' longer</strong> (a free bonus track I'll give you)</li>
  </ol>
`



class DemoFunnel extends PureComponent {
  constructor(props) {
    super(props);
    // const thisURL = checkURL(); // checks URL to set template. Can take a string param to force URLs (IE to check 'Endo' while on localhost. Only works on localhost)
    const thisURL = "zenstant"; // Uncomment above `thisURL` and delete this line to make webapp compatable with multiple websites (EndoZen, Zengraine, etc.)
    
    const formContent = {
			headline: `
				No time now? Want the track free (forever)? For just an email you'll own it and get a free short course on the easiest way to meditate regularly (even without your permission) PLUS an extra bonus track.
			`, 
			body:formBodyContentInHTML,
			cta: "Send me this track and bonuses, please!",
			giveawayTitle: "Instant Meditation",
			discountCode: "deepzen-big-discount", // note this is used by ConvertKit as a fallback, changes must be made to the form (or new forms created)
			discountedPrice: "9.95", // note this is used by ConvertKit as a fallback, changes must be made to the form (or new forms created)
			redirect: "https://zenstant.co/lp/thank-you-and-special-meditation-offer" // note this is used by ConvertKit as a fallback, changes must be made to the form (or new forms created)
		}


    this.state = {
      fileName: "instant-zen-demo.mp3",
      formContent: formContent
    };
    this.audioPlayer = React.createRef();
    Track(undefined, "PageView");
  }

  
    

	componentDidMount(){
		const GoogleOptimize = LoadGoogleOptimize();

		if (typeof window !== "undefined") {
			const gumLink = SetGumroadLink(); // Load gumroad
		}

	};




	render(){
		const marginSize= "2.5rem";

    return(
      <>
      	<Helmet>
        	<title>{theTitle}</title>
	        <link id='ico' rel="icon"  href={'/assets/zenstant-favicon.ico'}/>

					<meta name="description"     content="Special offer for Mindfulness Daily users" />
					<meta property="og:url"                content={"https://www.zenstant.co/" + window.location.pathname} />
					<meta property="og:type"               content="website" />
					<meta property="og:title"              content={theTitle} />
					<meta property="og:description"        content="Special offer for Mindfulness Daily users" />
					{/*
					<meta property="og:image"              content={"https://www.zenstant.co/assets/productImages/" + this.state.productInfo.imgFileName} />
					<meta property="og:image:width"        content="940" />
					<meta property="og:image:height"        content="788" />
					*/}
     		</Helmet>
	
				<div className="grid-container fluid">

					{/*<HeaderAndNav hideAllMenuOptions/>*/}

        	<div className="grid-x grid-margin-x" style={{textAlign:"center", zIndex: "5", backgroundColor:"#F0F3FA", paddingTop: marginSize}} >
        		{thePreHeadline &&
        		<p className="h5 cell small-10 small-offset-1 h5" style={{fontWeight: 900, color:"#FB637E"}}>
        			{thePreHeadline}
        		</p>
        		}
        		<DynamicH1 text={theHeadline} classes="cell small-10 small-offset-1" style={{fontWeight: 900}} />

        		{theSubhead &&
        		<h2 className="cell small-10 small-offset-1 h4" >
        			<em>{theSubhead}</em>
        		</h2>
        		}

        		

        		{supportingCopy && 
      			<div className="cell small-10 small-offset-1 large-8" style={{textAlign:"left", marginTop: marginSize}}>
      				<ReactMarkdown children={supportingCopy}/>
      			</div>
        		}
            <div className="cell small-12 medium-8 large-6" style={{alignSelf:"center", margin:"auto", marginTop:marginSize, marginBottom:marginSize}}>
	            <h3 style={{marginBottom: marginSize, color: "rgb(77, 77, 77)"}}>
	            	Try it, free forever. Just hit Play"
	            </h3>
	            
	            <PlayPauseButton self={this} audio={this.state.fileName} includeHeadphones />

	            <QuoteContainer 
	        			img="jerry-seinfeld.jpeg" 
	        			quote="It's like having a phone charger for your whole body and mind. That's what meditation is."
	        			name="Jerry Seinfeld"
	        		/>
  	        </div>
	        
	        
  	        <div className="cell small-12 medium-8 large-6" style={{margin: "auto", marginTop:marginSize, marginBottom:marginSize}}>


  	        	<ConvertKitForm 
  	        		content = {this.state.formContent}
  	        		uid = {uid}
  	        		svForm = {svForm}
  	        	/>
  	        </div>

  	        <SocialProof />
          	
              
          </div>

          <Footer />
        </div>
      </>
    )
	}
}

export default DemoFunnel

