import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch, useRouteMatch, Link } from 'react-router-dom';

import Homepage from './views/homepage'; 
import Header from "./modules/zenstant/headerAndNav";
import Footer from './modules/zenstant/footer';
import Contact  from'./views/contact';
import RequestReceived  from'./views/requestReceived';
import Segmented  from'./views/segmented';

import Products  from'./views/products';
import Product from './views/product';
import Manifesto  from'./views/manifesto';
import FreeContent from'./views/free';



// Simple order form pages (minimal content/distractions) - send people here from email sequences where all the sales copy has been sent via email
import SimpleOrderFormPageMeditation from "./views/simpleOrderForms/sofMeditation";


import DemoLP3 from './views/funnels/demoLp3-instant-meditation';
import DemoLP3Upsell from './views/funnels/thankYou-upsell-meditation';

import DemoLP4 from './views/funnels/demoLp4-instant-meditation2';
import DemoLP5 from './views/funnels/demoLp5-pain-relief';
import DemoLP6 from './views/funnels/demoLp6-meditation';



// import DemoLP99 from './views/funnels/demoLP99-free-52-weeks-of-content'; // Old version of /free, which is modeled after the landing pages


// const SecretDemo  = lazy(() => import('./views/funnels/secretDemoLP')); // This is unfinished and not working


const HiddenLinksForIndexing = lazy(() => import('./views/hidden-links-for-indexing-react-snap-only'))

// ==== LANDING PAGES ==== once there are a few it would be better to have designated routing, rather than hard coding
// const LandingPageIndexPage = lazy(() => import('./views/landingPages/'));
const ZenLP1 = lazy(() => import('./views/landingPages/zenLp1'));
const ZenLP2 = lazy(() => import('./views/landingPages/zenLp2'));
const ZenLP3 = lazy(() => import('./views/landingPages/zenLp3'));
const ChiroAffiliates = lazy(() => import('./views/landingPages/chiroAffiliates'));
const HealthAffiliates = lazy(() => import('./views/landingPages/healthAffiliates'));


// End landing pages

// ==== FUNNELS ==== 
const DemoLP1 = lazy(() => import('./views/funnels/demoLp1-electrofocus-free'));
const Demo1Upsell1 = lazy(() => import('./views/funnels/thankYou-upsell-essentials-has-electrofocus'));
const EssentialsOfferRejected = lazy(() => import('./views/funnels/essentials-offer-rejected'));
const UnwindPMOfferRejected = lazy(() => import('./views/funnels/unwindpm-offer-rejected'));
const OfferRejected = lazy(() => import('./views/funnels/offer-rejected'));

const PurchaseComplete = lazy(() => import('./views/funnels/purchase-complete'));

const DemoLP2 = lazy(() => import('./views/funnels/demoLp2-floataway-free'));
const DemoLP2Upsell1 = lazy(() => import('./views/funnels/thankYou-upsell-sleep_pack-has-floataway'));

// Demos 3 and 4 (and their upsell page) moved up into a regular import



// ==== NATIVE 'CONTENT' (for ads) ====

const NewMusicHelpsWomenOvercomeMenstrualPain  = lazy(() => import('./views/nativeAdContent/musicMenstrualPain'));
const NcLp2  = lazy(() => import('./views/nativeAdContent/nativeLp2'));
// END NATIVE 'CONTENT' (for ads)


// Blog
const Blog  = lazy(() => import('./views/blog'));
const BlogArticle = lazy(() => import('./views/blogArticle'));
// End blog

// memes
const Memes  = lazy(() => import('./views/memes'));

// Lazy load components
const ProductAbTestHandler  = lazy(() => import ('./modules/productAbTestHandler'));






// You'll find URL routes here

const App = props => {

  return(
    <> 
        <BrowserRouter>
          <div id="main-container">
            <Suspense fallback={<><Header hideAllMenuOptions /><div style={{minHeight: "90vh"}}>Loading...</div><Footer/></>}>

              <Switch>
                <Route exact path="/" component={Homepage} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/products" component={Products} /> 
                <Route exact path="/products/:id" component={ProductRouting} />

                <Route exact path="/manifesto" component={Manifesto} />
                <Route exact path="/free" component={FreeContent} /> {/* 52 weeks of content signup page */}

                <Route exact path="/request-received" component={RequestReceived} />
                <Route exact path="/segmented" component={Segmented} /> 


                {/*<Route exact path="/lp/secret-demo" component={SecretDemo} /> */} {/* Not currently working */}

                {/*Simple Order form pages*/}
                  {/*These are used when all the selling is done via email sequences. They should be hot and ready to buy at this point*/}

                <Route exact path="/stillness-order-form" component={SimpleOrderFormPageMeditation} />



                {/*<Route exact path="/blog" component={Blog} />*/} {/* Blog home. Not currently used. */}
                {/*<Route path="/blog/:id" component={BlogArticle} />*/} {/* Blog articles. Not currently used... */}
                
                <Route exact path="/hidden-links-for-indexing-react-snap-only" component={HiddenLinksForIndexing} /> {/* A somewhat hackey way to index pages (so they don't 404 before their lazy load) with react-snap */}


                {/* LANDING PAGES */}
                {/*<Route exact path="/lp/" component={LandingPageIndexPage} />*/} {/* Moved these to hidden-links-for-indexing-react-snap-only and plan to delete the other file. MUST UPDATE WHEN ADDING NEW LANDING PAGES. Link index of content, hidden from visitors.*/}
                <Route exact path="/lp/l1" component={ZenLP1} /> {/* LandingPage 1 */}
                {/*<Route exact path="/lp/l2" component={ZenLP2} />*/} {/* LandingPage 2 */}
                <Route exact path="/lp/l3" component={ZenLP3} /> {/* LandingPage 3 */}
                <Route exact path="/lp/demo1-get-electrofocus-free" component={DemoLP1} /> {/* LandingPage 3 */}
                <Route exact path="/lp/thank-you-and-special-offer" component={Demo1Upsell1} /> {/* LandingPage 3 */}
                <Route exact path="/lp/essentials-offer-rejected" component={EssentialsOfferRejected} /> {/* When a user rejects the special offer on the 'thank you and special offer' page */}
                <Route exact path="/lp/unwindpm-offer-rejected" component={UnwindPMOfferRejected} /> {/* When a user rejects the special offer on the 'thank you and special offer' page */}
                <Route exact path="/lp/offer-rejected" component={OfferRejected} /> {/* When a user rejects a special offer on a 'thank you and special offer' one-time-offer page. Going forward this is the generic one and should pass a url param of "offer-rejected={offer}" to the page */}

                <Route exact path="/lp/purchase-complete" component={PurchaseComplete} /> {/* When a user accepts the special offer on the "thank you and special offer" page */}
                <Route exact path="/lp/essentials-purchase-complete" component={PurchaseComplete} /> {/* Legacy/old */}
                
                <Route exact path="/lp/demo2-get-floataway-free" component={DemoLP2} /> {/* Sleep Funnel */}
                <Route exact path="/lp/thank-you-and-special-sleep-offer" component={DemoLP2Upsell1} /> {/* OTO upsell - more sleep */}

                <Route exact path="/lp/demo3-instant-meditation" component={DemoLP3} /> {/* Meditation Funnel */}
                <Route exact path="/lp/thank-you-and-special-meditation-offer" component={DemoLP3Upsell} /> {/* OTO upsell - meditation */}

                <Route exact path="/lp/demo4-meditation" component={DemoLP4} /> {/* Meditation Funnel V2 */}
                <Route exact path="/lp/demo-pain-relief" component={DemoLP5} /> {/* Meditation Funnel V2 */}

                <Route exact path="/lp/meditation" component={DemoLP6} /> {/* Meditation Funnel V2 */}


                <Route exact path="/lp/next-steps" component={OfferRejected} />

              {/* AFFILIATE LANDING PAGES */}
                <Route exact path="/affiliate/chiropractors" component={ChiroAffiliates} /> {/* Landing page for Chiro affiliates */}
                <Route exact path="/affiliate/health" component={HealthAffiliates} /> {/* Landing page for health (yoga, alternate medicine, etc) affiliates */}

                

                {/* NATIVE ADS "CONTENT" (glorified sales letters wrapped as content) */}
                {/*<Route exact path="/g-blog/" component={NativeContentIndexPage} /> */}{/* Moved all of these to "hidden-links-for-indexing-react-snap-only.js and plan to delete the file" MUST UPDATE WHEN ADDING NATIVE CONTENT. Link index of content, hidden from visitors.*/}
                <Route exact path="/g-blog/new-music-helps-women-overcome-menstrual-pain/" component={NewMusicHelpsWomenOvercomeMenstrualPain} />
                <Route exact path="/g-blog/nclp2/" component={NcLp2} />

                {/* MEMES - note these work pretty well... but they are currently all former-branded items that need to be updated before we consider bringing back the memes */}
                {/*<Route exact path="/funny/memes/:category/:id" component={AllMemes} />*/} {/* Specific funny meme shown page */}
                {/*<Route exact path="/funny/memes/:category/" component={AllMemes} /> */}{/* Funny meme categories */}
                {/*<Route exact path="/funny/memes/" component={AllMemes} />*/} {/* Funny memes page */}
                

                <Route component={the404} status={404}/>
              </Switch>
            </Suspense>
          </div>
        </BrowserRouter>

    </>
  )
}
export default App;




function AllMemes() {
  let match = useRouteMatch();


  let category;
  let id;

  try{
    category = match.params.category;
    id = match.params.id;

  }catch{
    category = null;
    id = null;
  }

  let thePath = '/funny/memes/' + ((category) ? category : "") + ((id) ? "/" + id : "");
  return (

      <Route path={thePath}>
        <Memes category={category} id={id} />
      </Route>

  )
}




function ProductRouting(){
    let match = useRouteMatch();
    let id;     // ID is used as the key for productCopy.js... make sure they're the same
    let abTest; // set this to true to enable creation of different elements for ab testing
    let location;
    
    const e = new Error("Response not found");

    try{
      location = match.params.id; // the tail-end of the URL... changed below because sometimes path!=id
    } catch (error) {
      // setup errors... in case of no product
      e.code = "ENOENT";  // Triggers a 404
      throw e;
    }

    switch (location) {
      case 'zenstant-solo':
        id = 'zenstantSolo';
        break;
      case 'zenstant-complete':
        id = 'zenstantComplete';
        break;
      case 'zenstant-essentials':
        id = 'zenstantEssentials';
      case 'stillness': // meditation
        id = "stillness";
        break;
      case 'serenity':  // anxiety
        id = "serenity";
        break;
      case 'unwind':  // anxiety
        id = "unwind";
        break;
      case 'pain-pause':  // anxiety
        id = "pain-pause";
        break;
      case 'zenstant-complete-2':
        id = 'zenstantComplete2';
        abTest = true;
        break;
      case 'zenstant-complete-3':
        // id = 'zenstantComplete3'; // uncomment when ready
        id = "zenstantComplete";     // delete before starting AB test
        abTest = true;
        break;
      case 'zenstant-complete-4':
        // id = 'zenstantComplete4'; // uncomment when ready
        id = "zenstantComplete";     // delete before starting AB test
        abTest = true;
        break;

      case 'liberate':
        id = 'liberate';
        break;
      default:
        e.code = "ENOENT";  // Triggers a 404
        throw e;
      }
  
  let thePath = '/products' + ((location) ? "/" + location : "");
  return(
      <Switch>
        <Route path={thePath}>
          <Product id={id} abTest={abTest}/>
          {/*<ProductAbTestHandler location={location} />*/}
        </Route>
      </Switch>
    )
}



const the404 = ({location, navigation}) => {
  
  // const e = new Error("404: page not found");
  // e.code = "ENOENT";  // Triggers a 404
  // throw e;

  return(
    <>
      <div className="grid-container fluid" >
        <Header/>
        <div className="grid-x grid-margin-x align-center" style={{minHeight:"80vh"}}>
          <div className="small-10 large-6 cell" style={{paddingTop: "6rem"}}>
            <h1 style={{color:"#000"}}>Rats! <code>{location.pathname}</code> isn't a page I recognize. That's a '404' error, in geek-speak.</h1>
            <button className="button" style={{marginRight: "2.25rem"}}><Link to="/" style={{color:"#000"}}>Go to homepage</Link></button>
            <button className="button" onClick={() => window.history.back()}>Go to previous page</button>
          </div>
          
        </div>
        <Footer/>
      </div>
    </>
  )
  
}






