import React from "react";
import CheckForLocalHost from './checkForLocalHost';

// Simple function to allow console logs that only show in localhost
const Log = (toLog = "", secondParam = null, thirdParam = null) => {
	let isLocal = CheckForLocalHost();

	if (isLocal === false){
		return false}
	else if( isLocal === true){
		if (thirdParam === null){
			console.log(toLog, secondParam);
		}else{console.log(toLog, secondParam, thirdParam);}
				
		return true
	} else{return false}
}

export default Log;