import React, { Component } from "react";
import ReactGA from "react-ga";
import {variantCopy} from "../../templateCopyText.js";
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import Log from "../../scripts/log";
import Track from "../../scripts/track";
import Image from "../../scripts/image";

import ReactMarkdown from 'react-markdown';

import HeaderAndNav from './headerAndNav';
import Footer from './footer';


import {productCopy} from "../../productCopy.js";

import SocialProof from './socialProof';
import FullTestimonials from '../../modules/fullTestimonials';

import TheScience from '../../modules/zenstant/theScience';
import SatisfactionGuarantee from '../../modules/zenstant/satisfactionGuarantee';



class AbTestDesign extends Component {
	constructor(props) {
		super(props);
		this.state = {
			productID: "",
			productHref: "",
			theImgSrc: "",
			productInfo: productCopy[this.props.id],
		  
		};
	}

	componentDidMount(){
		this.getSpecificProduct(); // Sets HTML to the proper product
		this.createEmbedScript(); // creates the gumroad script to get the embed	
	}

	createEmbedScript = () => {
		const script = document.createElement("script");
	    script.type = "text/javascript";
	    script.src ="https://gumroad.com/js/gumroad-embed.js";
	    document.body.appendChild(script);
	}

	getSpecificProduct = () => {
		const theGumroadHref = "https://gumroad.com/l/" + this.props.id;
		const theImgSrc = "/assets/productImages/" + productCopy[this.props.id].imgFileName;
		this.setState({
			productInfo: productCopy[this.props.id],
			productID: this.props.id,
			productHref: theGumroadHref,
			theImgSrc: theImgSrc,
		})
	}

	gumroadButton = (params = false) => { // Create gumroad purchase button. Takes object:key params of 'fullwidth' and 'words'
	    let gumButtonStyle = {};
	    let text;

	    let gumroadLink = this.state.productInfo.gumroadLink;
	    try {
	      text = params.words;
	    } catch {text = null}
	   
	    if (params.fullwidth) {
	        gumButtonStyle = {
	          width: "100%",
	      }
	    } else {
	      gumButtonStyle = {}
	    }

	    return(
	      <a  className="gumroad-button button" href={gumroadLink} target="_blank" data-gumroad-single-product="true" style={gumButtonStyle} >
	        {!text && <>Buy now</>} {text} ${productCopy[this.props.id].price}
	      </a>
	    );
	}

	//  ~~~~~~~MAIN HTML HERE!~~~~~~~

	render(){
		let template = variantCopy.zenstant; // Feature creep... this allowed us to create several sites with the same formats, but isn't used. However it must be included or many things fall apart.
		
		return(
			<>
				<ContentContainer className="grid-x grid-margin-x">
					
					<Link to="/products" className="small-12 cell" style={{marginBottom: "1rem"}}> &#60; All products</Link>
					<div className="small-12 medium-5 large-4 cell">
						{/*<ProductTitle className="h5 show-for-small-only">{this.state.productInfo.title}</ProductTitle>*/}
						<img src={this.state.theImgSrc} />	
						<div className="show-for-medium">
							{this.state.productInfo.comesWith && this.productComesWith()}						
						</div>
					</div>
					<div className="auto cell">
						<ProductTitle className="medium-h3">{this.state.productInfo.title}</ProductTitle>
						{this.state.productInfo.marketingTitle &&
							<MarketingTitle id="product-marketing-title" className="h4">
								 {this.state.productInfo.marketingTitle}
							</MarketingTitle>
						}
						<HR className="{/*show-for-medium*/}"/>

						{this.state.productInfo.marketingSubtitle &&
							<h3 id="product-marketing-subtitle" className="h5">
								 {this.state.productInfo.marketingSubtitle}
							</h3>
						}
						<p><ReactMarkdown source={this.state.productInfo.description}/></p>
						<h4>You'll get:</h4>
						<ul>
							{this.state.productInfo.benefitList && this.state.productInfo.benefitList.map(benefit => {
								return(
									<li><ReactMarkdown source={benefit} /></li>
								)
							})}
						</ul>
						<div className="show-for-small-only">
							{this.state.productInfo.comesWith && this.productComesWith()}							
						</div>
						
						<div onClick={() => Track(template, 'AddToCart' /*category*/, 'clicked-zenstant-painblock-gumroad-button' /*name (FB)/action(GA)*/ )} >
			              {this.gumroadButton({words: "Own it for only"})}
			            </div>
			            <em>All purchases protected by a 180 day guarantee. Either you're 100% happy or you're 100% refunded.</em>

			            
					</div>
  
					
				</ContentContainer>
				<SocialProof />
			
	            <div id="science-section-container" className="grid-x grid-margin-x">
		            <div className="small-12 medium-10 large-8 medium-offset-1 large-offset-2 cell text-center">
		              <h2 className="h5">Backed by proprietary science</h2>
		            </div>


		            <div className="small-12 medium-10 large-8 medium-offset-1 large-offset-2 cell">
		              <p>Through 20 years of research at Brown University and backed by grants including NASA, The National Institute of Health, and the National Science Foundation, we discovered...</p>
		              <div id="the-science-container" className="grid-x grid-margin-x small-up-1 medium-up-2">     
						<TheScience template={template}/>
		                
		              </div>
		            </div>
		              

					<div id="the-book" className="small-12 medium-8 large-4 medium-offset-2 large-offset-4 cell">
					  <p >
					    and even wrote the book on auditory neuroscience
					  </p>
					  <Image className="science-img" src="/assets/universal-sense-seth-horowitz-book.png" alt="The Universal Sense: How hearing affects the mind."/>
					  <p className="science-caption">The Universal Sense: How hearing affects the mind. By Dr. Seth Horowitz.</p>
					</div>
		        </div>
      			<SatisfactionGuarantee logLead={() => Track()} template={template} gumroadButton={this.gumroadButton({words: this.state.gumroadMainCtaText})} />

				<FullTestimonials />

      			<SatisfactionGuarantee logLead={() => Track()} template={template} gumroadButton={this.gumroadButton({words: this.state.gumroadMainCtaText})} />
				
			</>
		)
	}

	//  ~~~~~~~ /END MAIN HTML HERE!~~~~~~~



	productComesWith = () => {
		return(
			<>
				<h4>Included in your purchase:</h4>
				<ul>
					{this.state.productInfo.comesWith.map(item => {
						return(
							<li>{item}</li>
						)
					})}
				</ul>
				<DemoContainer className="" >
					{(this.state.productInfo.demos.length > 1) && 
						<h5>Demos</h5>
					}
					{(this.state.productInfo.demos.length === 1) &&<h5>Demo</h5>}


					{this.state.productInfo.demos.map((songTitle, index) => {
						return(
							<DemoInner>
								{(this.state.productInfo.demos.length > 1) &&  // If there are multiple demos...
									<p style={{marginBottom:"0"}}> {index + 1}:</p> // create string that says "Demo #" for each
								}
								<AudioDemo
									id="audioPlayer"
									key={songTitle}
									crossOrigin="anonymous"
									controls
									controlsList="nodownload"
									onPlay={() => Track(undefined /*template*/, 'ViewContent' /*category*/, 'PlayDemo' /*name (FB)/action(GA)*/, undefined /*value*/, false/*is custom FB event*/)}
								>
									<source src={'/assets/' + songTitle} type="audio/mpeg" /> 
								</AudioDemo>
							</DemoInner>
						)
					})}


				</DemoContainer>
			</>
		)
	}
}

export default AbTestDesign;

const ContentContainer = styled.div`
	padding: 1.5rem;
	background-color: #F0F3FA;
`

const ProductTitle = styled.h1`
	margin-bottom: 0;
	padding-bottom: 0;
	font-weight: 900;
`

const MarketingTitle = styled.h2`
	font-weight: 900;
`

const HR = styled.hr`
	margin-top: 0;
	margin-bottom: 0.75rem;
`

const DemoContainer = styled.div``

const DemoInner = styled.div`
	display: inline-flex;
	align-items: center;
`


const AudioDemo = styled.audio``






