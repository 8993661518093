import React from "react";
import {Helmet} from 'react-helmet';
import {variantCopy} from "../templateCopyText.js";



export const GetIco = (template = variantCopy.zenstant) => {
	const icoFilePath = '/assets/' + template.ico + '.ico';
	
	return(
	    <Helmet> 
	    	<link id='ico' rel="icon"  href={icoFilePath}/>
	    </Helmet>
    )
}